import React from 'react'
import { connect } from 'react-redux'
import { parse } from 'query-string'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'

import Cookies from 'util/cookies'
import { COOKIE_CLEVER_ID } from 'util/constants'
import BaseComponent, { mapDispatch } from 'components/base-component'
import { authenticateCleverOAuth } from 'actions/auth'
import FullScreen from 'components/session-common/full-screen'
import Menu from 'components/session-common/menu'

import errorMonsters from './images/login-oauth-error-monsters.png'
import errorOhNo from './images/login-oauth-error-oh-no.png'
import loadingMonsters from './images/login-oauth-loading-monsters.png'
import styles from './index.css'

class OAuth extends BaseComponent {
    constructor() {
        super()

        this.state = {
            error: false,
        }
    }
    componentDidMount() {
        if (this.props.student || this.props.tutor) {
            this.props.history.push('/')
        }

        const query = parse(this.props.location.search)

        if (!!query.code) {
            this.props.authenticateCleverOAuth(query.code)
        }
    }

    componentDidUpdate(prevProps) {
        const hasLoggedIn =
            (prevProps.student !== this.props.student &&
                !!this.props.student) ||
            (prevProps.tutor !== this.props.tutor && !!this.props.tutor)
        if (hasLoggedIn) {
            this.props.history.replace('/')
            return
        }

        if (
            prevProps.error.data !== this.props.error.data &&
            !!this.props.error.data
        ) {
            const { data } = this.props.error

            if (data.status === 404) {
                Cookies.set(COOKIE_CLEVER_ID, data.clever_id)
                const path =
                    data.type === 'student'
                        ? '/student/login'
                        : data.type === 'teacher'
                        ? '/tutor/login'
                        : '/'
                this.props.history.push(path)
                return
            }
            this.setState({ error: true })
        }
    }

    render() {
        if (this.state.error) {
            return (
                <div className={classNames(styles.root, styles.error)}>
                    <Menu />
                    <img className={styles.ohNo} src={errorOhNo} alt="Oh No!" />
                    {this.state.error && (
                        <div className={styles.subtitle}>
                            Something went wrong. Please close the browser
                            window and try again.
                        </div>
                    )}
                    <img
                        className={styles.errorMonsters}
                        src={errorMonsters}
                        alt="monsters"
                    />

                    <FullScreen />
                </div>
            )
        }

        return (
            <div className={styles.root}>
                <Menu />
                <div className={styles.title}>Here comes BookNook…</div>
                <img
                    className={styles.loadingMonsters}
                    src={loadingMonsters}
                    alt="monsters"
                />

                <FullScreen />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        student: state.student,
        tutor: state.tutor,
        error: state.error,
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatch({ authenticateCleverOAuth }))(OAuth)
)
