import {
    ADMIN_MULTISITES_GET,
    ADMIN_MULTISITES_GET_ALL,
    ADMIN_MULTISITES_GET_ALL_SCHOOLS,
    ADMIN_MULTISITES_ADD_SCHOOL,
    ADMIN_MULTISITES_REMOVE_SCHOOL,
    ADMIN_MULTISITES_UPDATE,
    CLEVER_SYNC_START,
    CLEVER_SYNC_VALIDATE_START,
    CLEVER_SYNC_GET_LATEST_FOR_MULTISITE,
    CLEVER_SYNC_VALIDATE_GET_LATEST_FOR_MULTISITE,
} from 'actions'

const DEFAULT_STATE = {
    multisites: [],
    schools: [],
    selectedMultisite: {
        multisite: null,
        cleverSyncResults: {
            job_id: null,
            progress: null,
            processed_on: null,
            finished_on: null,
            failed_reason: null,
            syncable_students_count: 0,
            synced_students_count: 0,
            created_at: null,
            errors: [],
            isLoaded: true,
            syncable: false,
        },
        cleverValidateSyncResults: {
            syncable_students_count: 0,
            created_at: null,
            isLoaded: true,
            syncable: false,
            errors: [],
            job_id: null,
            progress: null,
            processed_on: null,
            finished_on: null,
            failed_reason: null,
        },
    },
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case ADMIN_MULTISITES_GET:
            return {
                ...state,
                selectedMultisite: {
                    ...state.selectedMultisite,
                    multisite: action.payload,
                },
            }
        case ADMIN_MULTISITES_GET_ALL:
            return {
                ...DEFAULT_STATE,
                multisites: action.payload,
            }
        case ADMIN_MULTISITES_GET_ALL_SCHOOLS:
            return {
                ...state,
                schools: action.payload,
            }
        case ADMIN_MULTISITES_ADD_SCHOOL:
            return {
                ...state,
                selectedMultisite: {
                    ...state.selectedMultisite,
                    multisite: {
                        ...state.selectedMultisite.multisite,
                        schools: state.selectedMultisite.multisite.schools.concat(
                            action.meta.school
                        ),
                    },
                },
            }
        case ADMIN_MULTISITES_REMOVE_SCHOOL:
            return {
                ...state,
                selectedMultisite: {
                    ...state.selectedMultisite,
                    multisite: {
                        ...state.selectedMultisite.multisite,
                        schools: state.selectedMultisite.multisite.schools.filter(
                            s => s.id !== action.meta.school.id
                        ),
                    },
                },
            }
        case ADMIN_MULTISITES_UPDATE:
            return {
                ...state,
                selectedMultisite: {
                    ...state.selectedMultisite,
                    multisite: action.payload,
                },
            }
        case CLEVER_SYNC_VALIDATE_START:
        case CLEVER_SYNC_VALIDATE_GET_LATEST_FOR_MULTISITE:
            return {
                ...state,
                selectedMultisite: {
                    ...state.selectedMultisite,
                    cleverValidateSyncResults: {
                        ...state.selectedMultisite.cleverValidateSyncResults,
                        ...action.payload,
                        ...action.payload.value,
                        isLoaded: action.payload.progress === 'FINISHED',
                    },
                },
            }
        case CLEVER_SYNC_START:
        case CLEVER_SYNC_GET_LATEST_FOR_MULTISITE:
            return {
                ...state,
                selectedMultisite: {
                    ...state.selectedMultisite,
                    cleverSyncResults: {
                        ...state.selectedMultisite.cleverSyncResults,
                        ...action.payload,
                        ...action.payload.value,
                        isLoaded: action.payload.progress === 'FINISHED',
                    },
                },
            }
        default:
            return state
    }
}
