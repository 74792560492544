import FirebaseManager from 'util/firebase-manager'

// When I log in as a student, set my presence in firestore so that other users
// can monitor when students log in and out. Leverage firebase realtime database's
// presence monitoring to automatically set the student to offline if even if I
// abruptly close the tab.

class StudentPresenceBroadcaster {
    constructor(student) {
        this.student = student
        this.studentDatabaseRef = FirebaseManager.firebase
            .database()
            .ref(`/students/${this.student.id}`)

        this.studentFirestoreRef = FirebaseManager.firebase
            .firestore()
            .doc(`/students/${this.student.id}`)

        this.isOfflineForDatabase = {
            state: 'offline',
            schoolId: this.student.schoolId,
            lastChanged:
                FirebaseManager.firebase.database.ServerValue.TIMESTAMP,
        }

        this.isOnlineForDatabase = {
            state: 'online',
            schoolId: this.student.schoolId,
            lastChanged:
                FirebaseManager.firebase.database.ServerValue.TIMESTAMP,
        }

        this.isOfflineForFirestore = {
            state: 'offline',
            schoolId: this.student.schoolId,
            lastChanged: FirebaseManager.firebase.firestore.FieldValue.serverTimestamp(),
        }

        this.isOnlineForFirestore = {
            state: 'online',
            schoolId: this.student.schoolId,
            lastChanged: FirebaseManager.firebase.firestore.FieldValue.serverTimestamp(),
        }
    }

    // https://cloud.google.com/firestore/docs/solutions/presence
    // Implements a presense system using firebase realtime database,
    // and hook it up to our firestore using cloud functions.
    broadcast() {
        // Create a reference to the special '.info/connected' path in
        // Realtime Database. This path returns `true` when connected
        // and `false` when disconnected.
        FirebaseManager.firebase
            .database()
            .ref('.info/connected')
            .on('value', snapshot => {
                // If we're not currently connected, don't do anything.
                if (snapshot.val() === false) {
                    return
                }
                // If we are currently connected, then use the 'onDisconnect()'
                // method to add a set which will only trigger once this
                // client has disconnected by closing the app,
                // losing internet, or any other means.
                this.studentDatabaseRef
                    .onDisconnect()
                    .set(this.isOfflineForDatabase)
                    .then(() => {
                        // We can now safely set ourselves as 'online' knowing that the
                        // server will mark us as offline once we lose connection.
                        this.setOnline()
                    })
            })
    }

    setOnline() {
        this.studentDatabaseRef.set(this.isOnlineForDatabase)
        this.studentFirestoreRef.set(this.isOnlineForFirestore, { merge: true })
    }

    setOffline() {
        this.studentDatabaseRef.set(this.isOfflineForDatabase)
        this.studentFirestoreRef.set(this.isOfflineForFirestore, {
            merge: true,
        })
    }
}

export default StudentPresenceBroadcaster
