import React from 'react'
import styles from './index.css'

const Button = ({
    children,
    type = 'button',
    variantColor = 'primary',
    variant = 'solid',
    family = 'Lato',
    onClick,
    size,
    style,
    uppercase = true,
    className = '',
}) => {
    const classNames = [styles.btn]

    if (variant === 'solid') {
        switch (variantColor) {
            case 'primary':
                classNames.push(styles.solidPrimary)
                break
            case 'secondary':
                classNames.push(styles.solidSecondary)
                break
            default:
        }
    }

    if (variant === 'outline') {
        switch (variantColor) {
            case 'primary':
                classNames.push(styles.outlinePrimary)
                break
            default:
        }
    }

    switch (size) {
        case 'sm':
            classNames.push(styles.sm)
            break
        case 'lg':
            classNames.push(styles.lg)
            break
        case 'xl':
            classNames.push(styles.xl)
            break
        case 'xxl':
            classNames.push(styles.xxl)
            break
        default:
            classNames.push(styles.md)
    }

    if (family && family.match(/^lato$/i)) {
        classNames.push(styles.lato)
    }
    if (family && family.match(/^nunito$/i)) {
        classNames.push(styles.nunito)
    }
    if (uppercase) {
        classNames.push(styles.uppercase)
    }
    if (className) {
        classNames.push(className)
    }
    return (
        <button
            type={type}
            className={classNames.join(' ')}
            style={style}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export default Button
