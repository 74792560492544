import { connect } from 'react-redux'
import { mapDispatch } from 'components/base-component'
import { TermsOfServicePopup } from './TermsOfServicePopup.js'
import { updateAccount } from 'actions/tutor'

const mapStateToProps = state => {
    return {
        tutor: state.tutor,
    }
}

export default connect(
    mapStateToProps,
    mapDispatch({
        updateAccount,
    })
)(TermsOfServicePopup)
