import StudentPresenceBroadcaster from 'util/student-presence-broadcaster'
import SessionActiveBroadcaster from 'util/session-active-broadcaster'
import { DEMO_SESSION_ID } from 'util/demo-session-manager'
import { FIREBASE_CONFIG } from 'config'
import { updateDemoSessionState } from 'actions/session-demo'
import firebase from 'firebase/app'

let unsubSessionCallbacks = {}
let unsubSchoolStudentPresenceCallbacks = {}
let unsubStudentSessionCallbacks = {}

let studentPresenceBroadcasters = {}
let sessionActiveBroadcasters = {}

class FirebaseManager {
    static async initialize() {
        if (!!FirebaseManager.firebase) return

        await Promise.all([
            import('firebase/database'),
            import('firebase/firestore'),
            import('firebase/auth'),
        ])

        if (firebase.apps.length === 0) {
            firebase.initializeApp(FIREBASE_CONFIG)

            const firestore = firebase.firestore()
            const database = firebase.database()

            const settings = {}
            firestore.settings(settings)

            FirebaseManager.firebase = firebase
            FirebaseManager.database = database
        }
    }

    static async signIn(token) {
        await FirebaseManager.initialize()
        await FirebaseManager.firebase
            .auth()
            .setPersistence(FirebaseManager.firebase.auth.Auth.Persistence.NONE)

        return await FirebaseManager.firebase
            .auth()
            .signInWithCustomToken(token)
    }

    static async studentBroadcast(student) {
        studentPresenceBroadcasters[
            student.id
        ] = new StudentPresenceBroadcaster(student)
        studentPresenceBroadcasters[student.id].broadcast()
    }

    static async signOutStudent(student) {
        await FirebaseManager.initialize()
        if (student?.id) {
            await studentPresenceBroadcasters[student.id]?.setOffline()
            delete studentPresenceBroadcasters[student.id]
        }
        await FirebaseManager.firebase.auth().signOut()
    }

    static async signOut() {
        await FirebaseManager.initialize()
        return await FirebaseManager.firebase.auth().signOut()
    }

    static modifySessionState(sessionId, state, merge = true) {
        if (sessionId === DEMO_SESSION_ID) {
            // set timeout to mimic network
            setTimeout(() => updateDemoSessionState(state, merge), 0)
            return Promise.resolve()
        }

        const document = FirebaseManager.firebase
            .firestore()
            .doc(`sessions/${sessionId}`)
        return document.set({ state }, { merge })
    }

    static modifySessionStudents(sessionId, students, merge = true) {
        const document = FirebaseManager.firebase
            .firestore()
            .doc(`sessions/${sessionId}`)
        return document.set({ students }, { merge })
    }

    static getSession(sessionId) {
        const document = FirebaseManager.firebase
            .firestore()
            .doc(`sessions/${sessionId}`)
        return document.get().then(res => res.data())
    }

    static monitorSession(sessionId, handle, callback) {
        FirebaseManager.stopMonitoringSession(handle)

        const document = FirebaseManager.firebase
            .firestore()
            .doc(`sessions/${sessionId}`)

        const onNext = doc => {
            callback(doc.data(), null, doc.metadata)
        }
        const onError = e => callback(null, e)

        const cancel = document.onSnapshot(onNext, onError)

        unsubSessionCallbacks[handle] = cancel
    }

    static stopMonitoringSession(handle) {
        const cancel = unsubSessionCallbacks[handle]
        if (!!cancel) cancel()
        unsubSessionCallbacks[handle] = undefined
    }

    static async monitorSchoolStudentPresence(
        schoolId,
        handle,
        onStudentOnline = () => {},
        onStudentOffline = () => {}
    ) {
        await FirebaseManager.initialize()

        FirebaseManager.stopMonitoringSchoolStudentPresence(handle)

        const cancel = FirebaseManager.firebase
            .firestore()
            .collection('students')
            .where('state', '==', 'online')
            .where('schoolId', '==', schoolId)
            .onSnapshot(function(snapshot) {
                snapshot.docChanges().forEach(function(change) {
                    // FYI, to view the content of the change: change.doc.data()
                    if (change.type === 'added') {
                        onStudentOnline(Number(change.doc.id))
                    }
                    if (change.type === 'removed') {
                        onStudentOffline(Number(change.doc.id))
                    }
                })
            })

        unsubSchoolStudentPresenceCallbacks[handle] = cancel
    }

    static stopMonitoringSchoolStudentPresence(handle) {
        const cancel = unsubSchoolStudentPresenceCallbacks[handle]
        if (!!cancel) cancel()
        unsubSchoolStudentPresenceCallbacks[handle] = undefined
    }

    static async monitorStudentSession(
        studentId,
        handle,
        onSessionReady = () => {}
    ) {
        await FirebaseManager.initialize()

        FirebaseManager.stopMonitoringStudentSession(handle)

        const studentFirestoreRef = FirebaseManager.firebase
            .firestore()
            .doc(`/students/${studentId}`)

        const cancel = studentFirestoreRef.onSnapshot(docSnapshot => {
            const activeSessionId = Number(docSnapshot.data()?.activeSessionId)
            if (!Number.isNaN(activeSessionId)) {
                onSessionReady(activeSessionId)
            }
        })

        unsubStudentSessionCallbacks[handle] = cancel
    }

    static stopMonitoringStudentSession(handle) {
        const cancel = unsubStudentSessionCallbacks[handle]
        if (!!cancel) cancel()
        unsubStudentSessionCallbacks[handle] = undefined
    }

    static async broadcastSessionActive(session) {
        if (!session?.id) return
        await FirebaseManager.initialize()

        await FirebaseManager.stopBroadcastingSessionActive(session)

        sessionActiveBroadcasters[session.id] = new SessionActiveBroadcaster(
            session
        )
        sessionActiveBroadcasters[session.id].broadcast()
    }

    static async stopBroadcastingSessionActive(session) {
        if (!session?.id) return
        await sessionActiveBroadcasters[session?.id]?.setInactive()
        delete sessionActiveBroadcasters[session?.id]
    }

    static getNetworkTestDocument(testId) {
        return FirebaseManager.firebase
            .firestore()
            .doc(`networkTests/${testId}`)
    }
}

export default FirebaseManager
