import React from 'react'
import PropTypes from 'prop-types'
import styles from './index.css'

const Text = ({
    children,
    className,
    family = 'Lato',
    size,
    color,
    weight,
    uppercase,
    align,
    block,
}) => {
    let classNames = [styles.container]
    const style = {}
    if (family && family.match(/^lato$/i)) {
        classNames.push(styles.lato)
    }
    if (family && family.match(/^nunito$/i)) {
        classNames.push(styles.nunito)
    }
    if (family && family.match(/^Montserrat$/i)) {
        classNames.push(styles.montserrat)
    }
    // custom classNames
    if (className && typeof className === 'string') {
        classNames = classNames.concat(className.split(' '))
    }

    if (size) {
        style.fontSize = size
    }

    if (color) {
        style.color = color
    }
    // text weight
    switch (weight) {
        case 'medium':
            classNames.push(styles.fontWeightMedium)
            break
        case 'bold':
            classNames.push(styles.fontWeightBold)
            break
        default:
    }
    // text alignment
    switch (align) {
        case 'center':
            classNames.push(styles.alignCenter)
            break
        case 'right':
            classNames.push(styles.alignRight)
            break
        case 'left':
            classNames.push(styles.alignLeft)
            break
        default:
    }
    if (uppercase) {
        classNames.push(styles.uppercase)
    }

    if (block) {
        classNames.push(styles.block)
    }
    return (
        <span className={classNames.join(' ')} style={style}>
            {children}
        </span>
    )
}

Text.propTypes = {
    family: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    weight: PropTypes.string,
    uppercase: PropTypes.bool,
    align: PropTypes.string,
    block: PropTypes.bool,
}

export default Text
