export const INTRODUCTION_ACTIVITY_TYPE = 'INTRODUCTION'
export const MATCHING_ACTIVITY_TYPE = 'MATCHING'
export const CHOOSING_ACTIVITY_TYPE = 'CHOOSING'
export const WORD_LADDER_ACTIVITY_TYPE = 'WORDLADDER'
export const WORD_BUILDER_ACTIVITY_TYPE = 'WORDBUILDER'
export const GROUPING_ACTIVITY_TYPE = 'GROUPING'
export const ASSESSMENT_ACTIVITY_TYPE = 'ASSESSMENT'
export const BOOK_ACTIVITY_TYPE = 'BOOK'

export const ACTIVITY_TYPES = [
    INTRODUCTION_ACTIVITY_TYPE,
    MATCHING_ACTIVITY_TYPE,
    CHOOSING_ACTIVITY_TYPE,
    WORD_LADDER_ACTIVITY_TYPE,
    WORD_BUILDER_ACTIVITY_TYPE,
    GROUPING_ACTIVITY_TYPE,
    ASSESSMENT_ACTIVITY_TYPE,
    BOOK_ACTIVITY_TYPE,
]
