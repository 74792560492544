// eslint-disable-next-line
import './polyfills'

import React from 'react'
import { polyfill } from 'mobile-drag-drop'
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour'
import ReactDOM from 'react-dom'
import Auth from 'util/auth'
import App from 'containers/app'
import Notice from 'components/notice'
import SmallDeviceAlert from 'containers/small-device-alert'

import {
    isProduction,
    isQA,
    BROWSER_SUPPORTED,
    RAYGUN_KEY,
    GOOGLE_ANALYTICS_KEY,
    FORCE_BROWSER,
    VERSION,
    USER_AGENT,
} from 'config'
import rg4js from 'raygun4js'
import ReactGA from 'util/analytics'
import * as serviceWorker from './serviceWorker'

import './index.css'

let componentToRender

serviceWorker.register()

// iPad drag and drop fix:
// https://github.com/timruffles/mobile-drag-drop/issues/152
const ua = window.navigator.userAgent.toLowerCase()
const isiPad =
    ua.indexOf('ipad') > -1 ||
    (ua.indexOf('macintosh') > -1 && 'ontouchend' in document)

const usePolyfill = polyfill({
    forceApply: isiPad, // force apply for ipad
    dragImageTranslateOverride:
        scrollBehaviourDragImageTranslateOverride || isiPad,
})

if (usePolyfill) {
    document.addEventListener('dragenter', event => event.preventDefault())
    window.addEventListener('touchmove', () => {}, { passive: false })
}

if (isProduction() || isQA()) {
    rg4js('apiKey', RAYGUN_KEY)
    rg4js('enableCrashReporting', true)
    rg4js('setVersion', VERSION)

    ReactGA.initialize(GOOGLE_ANALYTICS_KEY)

    if (Auth.isTutorLoggedIn()) {
        const tutorId = Auth.getLoggedInTutorId()
        ReactGA.set({
            userId: tutorId,
            dimension2: 'TUTOR', //userType
            dimension3: tutorId, //tutorId
        })
    } else if (Auth.isStudentLoggedIn()) {
        const studentId = Auth.getLoggedInStudentId()
        ReactGA.set({
            userId: studentId,
            dimension2: 'STUDENT', //userType
        })
    }
}

function BnBookElement() {
    return Reflect.construct(HTMLElement, [], BnBookElement)
}
Object.setPrototypeOf(BnBookElement.prototype, HTMLElement.prototype)
Object.setPrototypeOf(BnBookElement, HTMLElement)

function BnPageElement() {
    return Reflect.construct(HTMLElement, [], BnPageElement)
}
Object.setPrototypeOf(BnPageElement.prototype, HTMLElement.prototype)
Object.setPrototypeOf(BnPageElement, HTMLElement)

customElements.define('bn-page', BnBookElement)
customElements.define('bn-book', BnPageElement)

const handleBrowserNotSupported = () => {
    window.location.replace('https://www.google.com/chrome/browser')
}

if (!BROWSER_SUPPORTED && !FORCE_BROWSER) {
    componentToRender = (
        <Notice
            header="Uh-oh, it looks like you’re using an unsupported web browser!"
            subheader={`${USER_AGENT.browser.name} version ${USER_AGENT.browser.major}`}
            details="BookNook currently supports Chrome, version 54 and above, Edge, version 79 and above, as well as Safari, version 9 and above. We recommend downloading the latest version of Chrome for the best BookNook experience."
            ctaBtnText="Get Chrome"
            onClick={handleBrowserNotSupported}
        />
    )
}

const isDeviceSmall = () => {
    if (!window) return false
    const height = window.screen.height
    const width = window.screen.width
    const sorted = [height, width].sort((a, b) => a - b)
    return sorted[0] < 600
}

if (isDeviceSmall()) {
    componentToRender = <SmallDeviceAlert />
}

ReactDOM.render(componentToRender || <App />, document.getElementById('root'))
