import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import activeSession from './active-session'
import activeSessionGroup from './active-session-group'
import adminBooks from './admin-books'
import adminLessons from './admin-lessons'
import adminMultisites from './admin-multisites'
import adminPassages from './admin-passages'
import adminReports from './admin-reports'
import adminSchools from './admin-schools'
import adminStandards from './admin-standards'
import adminStudents from './admin-students'
import adminTutors from './admin-tutors'
import app from './app'
import appLayout from './app-layout'
import comprehension from './comprehension'
import fluency from './fluency'
import genderTypes from './gender-types'
import gradeLevels from './grade-level'
import importGuides from './import-guides'
import importStudents from './import-students'
import kOne from './k-one'
import leveling from './leveling'
import manageSchool from './manage-school'
import networkTest from './network-test'
import popup from './popup'
import races from './race'
import readingLevels from './reading-level'
import session from './session'
import sessionTypes from './session-type'
import sessionSetup from './session-setup'
import sessionFeedback from './session-feedback'
import student from './student'
import toast from './toast'
import tutor from './tutor'
import tutorSchools from './tutor-schools'
import tutorMultisites from './tutor-multisites'
import tutorTypes from './tutor-types'
import tutorPublisherAdmin from './tutor-publisher-admin'
import vocabulary from './vocabulary'
import wrapup from './wrapup'
import programs from './programs'
import timeblocks from './timeblocks'
import error from './error'

export default combineReducers({
    activeSession,
    activeSessionGroup,
    adminBooks,
    adminLessons,
    adminMultisites,
    adminPassages,
    adminReports,
    adminSchools,
    adminStandards,
    adminStudents,
    adminTutors,
    app,
    appLayout,
    comprehension,
    fluency,
    form,
    genderTypes,
    gradeLevels,
    importGuides,
    importStudents,
    kOne,
    leveling,
    manageSchool,
    networkTest,
    popup,
    races,
    readingLevels,
    session,
    sessionTypes,
    sessionSetup,
    sessionFeedback,
    student,
    toast,
    tutor,
    tutorSchools,
    tutorMultisites,
    tutorTypes,
    tutorPublisherAdmin,
    vocabulary,
    wrapup,
    programs,
    timeblocks,
    error,
})
