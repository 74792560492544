import { alertError } from 'actions/popup'
import { useDispatch } from 'react-redux'

export const useStartRequests = () => {
    const dispatch = useDispatch()

    const startRequests = requests => {
        const validateAction = action => {
            return new Promise((res, rej) => {
                if (action.error) {
                    dispatch(alertError(action.payload, action.type))
                    rej(action.payload)
                } else {
                    res(action.payload)
                }
            })
        }

        return Promise.all(
            requests.map(dispatch).map(p => p.then(validateAction))
        )
    }

    return startRequests
}
