import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import StudentLoginErrorNudge from 'components/student-login-error-nudge'
import { openPopup, openAlert } from 'actions/popup'

const ErrorNotification = props => {
    const isCentralized = useSelector(state => state.error.isCentralized)
    const error = useSelector(state => state.error.data)

    const dispatch = useDispatch()

    useEffect(() => {
        if (isCentralized) {
            switch (error.status) {
                case 401: {
                    if (
                        error.payload &&
                        Object.keys(error.payload).includes('email')
                    ) {
                        // it's a tutor login
                        const message = `Please confirm that you have entered the correct credentials.  
                        For tutors this is an email address and password.`
                        dispatch(openAlert(message))
                        return
                    }

                    if (
                        error.payload &&
                        Object.keys(error.payload).includes('username')
                    ) {
                        // it's a student login
                        const content = <StudentLoginErrorNudge />
                        dispatch(openPopup(content))
                        return
                    }
                    dispatch(openAlert(error.message))
                    return
                }
                case 400: {
                    const message = (
                        <div>
                            The following errors occurred:
                            <br />
                            {error.validationErrors.map(e => (
                                <div key={e.field} className="validation-error">
                                    {e.field} {e.message}
                                </div>
                            ))}
                        </div>
                    )
                    dispatch(openAlert(message))
                    return
                }
                case 500: {
                    const message = `An unexpected error has occurred with the BookNook servers.  
                    If this continues please contact technical support.`
                    dispatch(openAlert(message))
                    return
                }
                default:
                    const message = error.message
                    dispatch(openAlert(message))
                    return
            }
        }
    }, [error, isCentralized, dispatch])

    return null
}

export default ErrorNotification
