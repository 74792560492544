import PropTypes from 'prop-types'
import React from 'react'
import { PROMPT_KIND } from 'util/constants'
import styles from './index.css'

const Prompt = props => {
    const onPrimaryClicked = () => {
        if (props.primaryButtonEnabled) {
            props.onPrimaryClicked()
        }
    }

    const primaryStyle =
        props.kind === PROMPT_KIND.NOTIFY
            ? styles.primary
            : styles.primaryWarning

    let secondaryButton
    if (!!props.secondaryButtonText && !!props.onSecondaryClicked) {
        secondaryButton = (
            <div
                role="button"
                className={`${styles.button} ${styles.secondary}`}
                onClick={props.onSecondaryClicked}
            >
                {props.secondaryButtonText}
            </div>
        )
    }

    return (
        <div className={styles.root}>
            <div
                className={
                    props.customStyles?.text
                        ? styles.text + ' ' + props.customStyles?.text
                        : styles.text
                }
            >
                {props.children}
            </div>
            <div
                className={
                    props.customStyles?.btnPrimary
                        ? styles.buttons + ' ' + props.customStyles?.btnPrimary
                        : styles.buttons
                }
            >
                {secondaryButton}
                <div
                    role="button"
                    className={`${styles.button} ${primaryStyle} ${
                        !props.primaryButtonEnabled ? styles.disabled : ''
                    }`}
                    onClick={onPrimaryClicked}
                >
                    {props.primaryButtonText}
                </div>
            </div>
        </div>
    )
}

Prompt.propTypes = {
    kind: PropTypes.string.isRequired,
    onPrimaryClicked: PropTypes.func.isRequired,
    onSecondaryClicked: PropTypes.func,
    primaryButtonEnabled: PropTypes.bool,
    primaryButtonText: PropTypes.string.isRequired,
    secondaryButtonText: PropTypes.string,
    customStyles: PropTypes.object,
}

Prompt.defaultProps = {
    kind: PROMPT_KIND.NOTIFY,
    primaryButtonText: 'Ok',
    primaryButtonEnabled: true,
}

export default Prompt
