import ApiClient from 'util/book-nook-api-client'
import {
    AUTH_LOGOUT,
    AUTH_STUDENT,
    AUTH_TUTOR,
    AUTH_FIREBASE,
    AUTH_CLEVER,
    LOGIN_STUDENT,
    LOGIN_TUTOR,
} from 'actions'
import Auth from 'util/auth'

export function authenticateStudent(username) {
    return {
        type: LOGIN_STUDENT,
        payload: { username },
    }
}

export function authenticateStudentBadge(badgeId) {
    const payload = ApiClient.authenticateStudentBadge(badgeId).then(
        Auth.postStudentAuthentication
    )

    return {
        type: AUTH_STUDENT,
        payload,
    }
}

export function authenticateCleverOAuth(code) {
    return {
        type: AUTH_CLEVER,
        payload: code,
    }
}

export function authenticateTutor(email, password) {
    return {
        type: LOGIN_TUTOR,
        payload: { email, password },
    }
}

export function claimTutor(email) {
    const payload = ApiClient.claimTutor(email).then(
        Auth.postTutorAuthentication
    )

    return {
        type: AUTH_TUTOR,
        payload,
    }
}

export function authenticateFirebase() {
    const payload = ApiClient.authenticateFirebase()

    return {
        type: AUTH_FIREBASE,
        payload,
    }
}

export function logout() {
    return { type: AUTH_LOGOUT }
}
