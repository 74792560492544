import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import onClickOutside from 'react-onclickoutside'
import styles from './index.css'
import { useSelector } from 'react-redux'
import { useStartRequests } from 'hooks/useStartRequests'
import { getAllPrograms } from 'actions/programs'

function AdminTutoringMenu() {
    const history = useHistory()
    const { location } = history
    const { programs } = useSelector(state => state.programs)
    const startRequests = useStartRequests()

    const [state, setState] = useState({
        menuOpen: false,
        programSelected: '',
        firstRequest: true,
    })
    const { menuOpen, programSelected, firstRequest } = state

    const fetchData = useCallback(async () => {
        const [data] = await startRequests([getAllPrograms()])
        data && setState(prev => ({ ...prev, firstRequest: false }))
    }, [startRequests])

    const onMenuClick = event => {
        toggleMenu()
        event.stopPropagation()
    }
    const toggleMenu = () =>
        setState(prev => ({ ...prev, menuOpen: !prev.menuOpen }))

    const onNav = url => history.push(url)

    AdminTutoringMenu.handleClickOutside = () =>
        setState(prev => ({ ...prev, menuOpen: false }))

    useEffect(() => {
        const { pathname } = history.location
        let programId

        const overviewRegExp = new RegExp(`(/overview)`)
        const studentsRegExp = new RegExp(`/students`)
        const guidesRegExp = new RegExp(`/tutors`)
        const groupsRegExp = new RegExp(`/groups`)
        const adminRegExp = new RegExp(`(/admin/tutoring/program/)`)

        const isOverviewPage = overviewRegExp.test(pathname)
        const isTutoringPage = adminRegExp.test(pathname)
        const isStudentsPage = studentsRegExp.test(pathname)
        const isGuidesPage = guidesRegExp.test(pathname)
        const isGroupsPage = groupsRegExp.test(pathname)

        if (isOverviewPage && isTutoringPage) {
            programId = pathname
                .replace(adminRegExp, '')
                .replace(overviewRegExp, '')
        }

        if (isTutoringPage && isStudentsPage) {
            programId = pathname
                .replace(adminRegExp, '')
                .replace(studentsRegExp, '')
        }

        if (isTutoringPage && isGuidesPage) {
            programId = pathname
                .replace(adminRegExp, '')
                .replace(guidesRegExp, '')
        }

        if (isTutoringPage && isGroupsPage) {
            programId = pathname
                .replace(adminRegExp, '')
                .replace(groupsRegExp, '')
        }

        if (programId && programs.length) {
            const _programSelected = programs.find(
                program => program.id === parseInt(programId)
            )
            if (_programSelected) {
                // Select the program by ID in location pathname
                setState(prev => ({
                    ...prev,
                    programSelected: _programSelected.name,
                }))
            } else {
                // Select the first program by default
                setState(prev => ({
                    ...prev,
                    programSelected: programs[0].name,
                }))
                history.replace(
                    `/admin/tutoring/program/${programs[0].id}/overview`
                )
            }
        }

        if (!programId && programs.length) {
            setState(prev => ({
                ...prev,
                programSelected: programs[0].name,
            }))
        }
    }, [history, location, programs])

    useEffect(() => {
        if (firstRequest) {
            fetchData()
        }
    }, [firstRequest, fetchData])

    return (
        <>
            {programs.length ? (
                <div
                    className={styles.menu}
                    onClick={onMenuClick}
                    onBlur={onMenuClick}
                >
                    <div
                        className={`${styles.tab} ${
                            menuOpen ? `${styles.tabActive}` : ''
                        }`}
                    >
                        {programSelected}
                    </div>
                    {menuOpen && (
                        <div className={styles.programsPopup}>
                            {programs.map(({ id, name }) => (
                                <div
                                    key={id}
                                    className={styles.menuItem}
                                    onClick={() =>
                                        onNav(
                                            `/admin/tutoring/program/${id}/overview`
                                        )
                                    }
                                >
                                    {name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : null}
        </>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () => AdminTutoringMenu.handleClickOutside,
}

export default onClickOutside(AdminTutoringMenu, clickOutsideConfig)
