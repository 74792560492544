const get = key => {
    return window.localStorage.getItem(key)
}

const set = (key, value) => {
    if (value !== undefined) window.localStorage.setItem(key, value)
}

const remove = key => {
    return window.localStorage.removeItem(key)
}

export default { set, get, remove }
