import {
    GET_LIST_TIMEBLOCKS,
    GET_TIMEBLOCK,
    CREATE_TIMEBLOCK,
    UPDATE_TIMEBLOCK,
    REMOVE_TIMEBLOCK,
    GET_TIMEBLOCKS_COUNT,
    INITIALIZE,
} from 'actions'

const initialState = {
    timeblocks: [],
    timeblock: {},
    createTimeblock: {},
    updateTimeblock: {},
    removeTimeblock: {},
    timeblocksCount: 0,
}

export default (state = initialState, action) => {
    if (action.error) return state

    switch (action.type) {
        case GET_LIST_TIMEBLOCKS:
            return {
                ...state,
                timeblocks: action.payload,
            }
        case GET_TIMEBLOCK:
            return {
                ...state,
                timeblock: action.payload,
            }
        case CREATE_TIMEBLOCK:
            return {
                ...state,
                createTimeblock: action.payload,
            }
        case UPDATE_TIMEBLOCK:
            return {
                ...state,
                updateTimeblock: action.payload,
            }
        case REMOVE_TIMEBLOCK:
            return {
                ...state,
                removeTimeblock: action.payload,
            }
        case GET_TIMEBLOCKS_COUNT:
            return {
                ...state,
                timeblocksCount: action.payload.count,
            }
        case INITIALIZE:
            return {
                ...initialState,
                isLoggedIn: true,
            }
        default:
            return state
    }
}
