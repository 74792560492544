import ApiClient from 'util/book-nook-api-client'
import FirebaseManager from 'util/firebase-manager'
import {
    SESSION_ACKNOWLEDGE_STUDENT_STATUS_TIMEOUT_RESET,
    SESSION_ADD_FEATURE,
    SESSION_ALERT_TEACHER,
    SESSION_COMPLETE,
    SESSION_DELETE,
    SESSION_DISMISS_ALERT,
    SESSION_END,
    SESSION_GET_FOR_STUDENT,
    SESSION_JOIN,
    SESSION_LAST_SEEN,
    SESSION_REMOVE_STUDENTS,
    SESSION_RESET_STUDENT_NEEDS_ATTENTION,
    SESSION_SET,
    SESSION_STATE_SET_CHECKPOINT,
    SESSION_STUDENT_SYNC_READY,
    SESSION_STUDENT_UPDATE_LAST_CHECKPOINT,
    SESSION_SYNC_STUDENTS,
    SESSION_UPDATE_STUDENT_STATUS,
    SESSION_GET_TUTOR,
} from 'actions'
import { isBookCheckpoint } from 'util/session'

export function startSession(
    sessionTypeId,
    studentIds,
    lessonId,
    isSpanish = false,
    state,
    readingLevelIdOverride = null
) {
    const payload = ApiClient.startSession(
        sessionTypeId,
        studentIds,
        lessonId,
        isSpanish,
        state,
        readingLevelIdOverride
    )

    return {
        type: SESSION_SET,
        payload,
    }
}

export function removeStudents(session, studentsToRemove) {
    const students = session.students.filter(
        s1 => !studentsToRemove.some(s2 => s1.id === s2.id)
    )

    const payload = Promise.all([
        FirebaseManager.modifySessionStudents(session.id, students),
        ...studentsToRemove.map(s =>
            ApiClient.removeStudentFromSession(session.id, s.id)
        ),
    ])

    return {
        type: SESSION_REMOVE_STUDENTS,
        payload,
    }
}

export function getActiveSession(sessionId) {
    const payload = FirebaseManager.getSession(sessionId)
    return {
        type: SESSION_SET,
        payload,
    }
}

export function setActiveSession(session) {
    return {
        type: SESSION_SET,
        payload: session,
    }
}

export function joinSession(sessionId, studentId) {
    const payload = ApiClient.joinSession(sessionId, studentId)

    return {
        type: SESSION_JOIN,
        payload: payload,
    }
}

export function getStudentSessions(studentId) {
    const payload = ApiClient.getStudentSessions(studentId)

    return {
        type: SESSION_GET_FOR_STUDENT,
        payload,
    }
}

export function setCheckpoint(activeSession, checkpointId, meta = {}) {
    const { id: sessionId, state } = activeSession

    // REJOIN TO BOOK
    const isBookNext = isBookCheckpoint(checkpointId)
    const hasSeenBook = state.hasSeenBook || isBookNext

    const currentCheckpoint = state.checkpointId
    const snapshot = isBookCheckpoint(currentCheckpoint)
        ? {
              ...state,
              hasSeenBook,
              checkpointId: currentCheckpoint,
          }
        : null
    // ---

    const payload = FirebaseManager.modifySessionState(
        sessionId,
        meta.useSnapshot && state.snapshot
            ? { ...state.snapshot, snapshot: state.snapshot }
            : {
                  checkpointId,
                  hasSeenBook,
                  snapshot: state.snapshot || snapshot,
                  ...meta,
              }
    )

    return {
        type: SESSION_STATE_SET_CHECKPOINT,
        payload,
    }
}

export function updateStudentStatus(
    session,
    studentId,
    status = {},
    meta = {}
) {
    const { id: sessionId, state } = session

    const prevHasSeenBook = state.hasSeenBook
    const hasSeenBook = Boolean(
        session.state.hasSeenBook ||
            (status.checkpointId && isBookCheckpoint(status.checkpointId))
    )
    const takeBookSnapshot = hasSeenBook && prevHasSeenBook !== hasSeenBook

    const bookSnapshot = takeBookSnapshot
        ? {
              ...state,
              hasSeenBook,
          }
        : null

    const newState = {
        studentStatus: {
            [studentId]: { ...status, lastSeenAt: new Date().getTime() },
        },
        hasSeenBook: hasSeenBook,
        snapshot: state.snapshot || bookSnapshot,
    }

    const payload = FirebaseManager.modifySessionState(
        sessionId,
        meta.useSnapshot && state.snapshot
            ? { ...state.snapshot, snapshot: state.snapshot }
            : newState
    )

    return {
        type: SESSION_UPDATE_STUDENT_STATUS,
        payload,
    }
}

export function updateStudentLastSeenAt(session, studentId) {
    return updateStudentStatus(session, studentId)
}

export function setStudentCheckpoint(session, studentId, checkpointId) {
    return updateStudentStatus(session, studentId, { checkpointId })
}

export function setAllStudentCheckpoints(sessionId, studentIds, checkpointId) {
    const state = { studentStatus: {} }
    studentIds.forEach(sid => {
        state.studentStatus[sid] = { checkpointId }
    })
    const payload = FirebaseManager.modifySessionState(sessionId, state)

    return {
        type: SESSION_UPDATE_STUDENT_STATUS,
        payload,
    }
}

export function setStudentNeedsAttention(session, studentId) {
    return updateStudentStatus(session, studentId, { needsAttention: true })
}

export function resetStudentNeedsAttention(sessionId, studentId) {
    const state = { studentStatus: {} }
    const status = {
        needsAttention: false,
        lastSeenAt: new Date().getTime(),
    }
    state.studentStatus[studentId] = status
    const payload = FirebaseManager.modifySessionState(sessionId, state)

    return {
        type: SESSION_RESET_STUDENT_NEEDS_ATTENTION,
        payload,
    }
}

export function alertTeacher(session, studentId, message) {
    const previousAlerts = session.state.studentStatus[studentId].alerts

    let alerts = previousAlerts ? previousAlerts : []
    alerts = [
        {
            createdAt: new Date().getTime(),
            message,
        },
        ...alerts,
    ]

    const state = { studentStatus: {} }
    const status = { alerts }

    session.state.studentStatus[studentId] = status
    state.studentStatus[studentId] = status
    const payload = FirebaseManager.modifySessionState(session.id, state)

    return {
        type: SESSION_ALERT_TEACHER,
        payload,
    }
}

export function dismissAlert(session, studentId, createdAt) {
    const previousAlerts = session.state.studentStatus[studentId].alerts

    let alerts = previousAlerts ? previousAlerts : []

    alerts = alerts.filter(alert => {
        return alert.createdAt !== createdAt
    })

    const state = { studentStatus: {} }
    const status = { alerts }

    session.state.studentStatus[studentId] = status
    state.studentStatus[studentId] = status
    const payload = FirebaseManager.modifySessionState(session.id, state)

    return {
        type: SESSION_DISMISS_ALERT,
        payload,
    }
}

export function acknowledgeStudentStatusTimeoutReset() {
    return {
        type: SESSION_ACKNOWLEDGE_STUDENT_STATUS_TIMEOUT_RESET,
    }
}

export function updateStudentScore(session, studentId, sessionScore) {
    const studentState = session.state.studentStatus[studentId]
    const totalScore = studentState.initialScore + sessionScore

    return ApiClient.updateStudentScore(studentId, totalScore).then(() =>
        updateStudentStatus(session, studentId, { sessionScore })
    )
}

export function addToStudentScore(session, studentId, scoreToAdd) {
    const studentState = session.state.studentStatus[studentId]
    const sessionScore = studentState.sessionScore + scoreToAdd

    return updateStudentScore(session, studentId, sessionScore)
}

export function initSync(sessionId, syncId, startTime) {
    const syncs = {}
    syncs[syncId] = { students: {}, startTime }

    const payload = FirebaseManager.modifySessionState(sessionId, { syncs })

    return {
        type: SESSION_SYNC_STUDENTS,
        payload,
    }
}

export function studentSyncReady(sessionId, studentId, syncId) {
    const syncs = {}
    syncs[syncId] = { students: {} }
    syncs[syncId].students[studentId] = { ready: true }

    const payload = FirebaseManager.modifySessionState(sessionId, { syncs })

    return {
        type: SESSION_STUDENT_SYNC_READY,
        payload,
    }
}

export function completeSession(sessionId) {
    return { type: SESSION_COMPLETE, payload: sessionId }
}

export function endSession() {
    return { type: SESSION_END }
}

export function deleteSession(sessionId) {
    const payload = ApiClient.deleteSession(sessionId)

    return { type: SESSION_DELETE, payload: payload }
}

export function updateSessionLastSeen(sessionId) {
    const payload = ApiClient.updateSessionLastSeen(sessionId)

    return {
        payload,
        type: SESSION_LAST_SEEN,
    }
}

export function addSessionFeature(sessionId, feature) {
    const state = {
        features: [feature],
    }

    const payload = FirebaseManager.modifySessionState(sessionId, state)

    return {
        payload,
        type: SESSION_ADD_FEATURE,
    }
}

export function updateSessionStudentLastCheckpoint(
    sessionId,
    studentId,
    checkpointId,
    sequence
) {
    const payload = ApiClient.updateSessionStudentLastCheckpoint(
        sessionId,
        studentId,
        checkpointId,
        sequence
    )

    return {
        payload,
        type: SESSION_STUDENT_UPDATE_LAST_CHECKPOINT,
    }
}

export function getTutorFromSessionId(sessionId) {
    const payload = ApiClient.getTutorFromSessionId(sessionId)
    return {
        payload,
        type: SESSION_GET_TUTOR,
    }
}
