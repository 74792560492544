import VersionData from './version.json'
import { getQueryParameter } from 'util/query-string-parser'
import UAParser from 'ua-parser-js'

const MIN_SUPPORTED_CHROME_MAJOR = 50
const MIN_SUPPORTED_SAFARI_MAJOR = 9
const MIN_SUPPORTED_WEBKIT_MAJOR = 600
const MIN_SUPPORTED_ANDROID_BROWSER_MAJOR = 4
const ENVIRONMENT_DEVELOPMENT = 'DEVELOPMENT'
const ENVIRONMENT_PRODUCTION = 'PRODUCTION'
const ENVIRONMENT_QA = 'QA'
const ENVIRONMENT_DEMO = 'DEMO'
const ENVIRONMENT_STAGING = 'STAGING'
const API_URL_TUTORING_QA = 'https://api-tutoring-qa.booknooklearning.com'
const API_URL_TUTORING_PRODUCTION = 'https://api-tutoring.booknooklearning.com'
const ENVIRONMENT_TUTORING_QA = 'TUTORING_QA'
const ENVIRONMENT_TUTORING_PROD = 'TUTORING_PROD'
const API_URL_DEVELOPMENT = 'http://localhost:1337'
const API_URL_PRODUCTION = 'https://api.booknooklearning.com'
const API_URL_QA = 'https://api-qa.booknooklearning.com'
const API_URL_DEMO = 'https://api-demo.booknooklearning.com'
const API_URL_STAGING = 'https://api-staging.booknooklearning.com'
const RAYGUN_KEY_PRODUCTION = 'qQM6hQqMpUUCUyhUrDb00w=='
const RAYGUN_KEY_QA = 'ct6Kg3/xOmAMDWZIyqJJUQ=='
const RAYGUN_KEY_STAGING = 'XO5cNHr6XoXpn4VjpMfGYw=='
const GOOGLE_ANALYTICS_KEY_PROD = 'UA-89995152-2'
const GOOGLE_ANALYTICS_KEY_QA = 'UA-161224919-1'

const parseEnvironment = () => {
    if (window.location.hostname === 'localhost') return ENVIRONMENT_DEVELOPMENT
    else if (window.location.hostname === 'app-qa.booknooklearning.com')
        return ENVIRONMENT_QA
    else if (window.location.hostname === 'app-demo.booknooklearning.com')
        return ENVIRONMENT_DEMO
    else if (window.location.hostname === 'app-staging.booknooklearning.com')
        return ENVIRONMENT_STAGING
    else if (window.location.hostname === 'app.booknooklearning.com')
        return ENVIRONMENT_PRODUCTION
    else if (
        window.location.hostname === 'app-tutoring-qa.booknooklearning.com'
    )
        return ENVIRONMENT_TUTORING_QA
    else if (window.location.hostname === 'app-tutoring.booknooklearning.com')
        return ENVIRONMENT_TUTORING_PROD
    else return ''
}

export const getWrapper = () => {
    let wrapper = getQueryParameter('wrapper')
    if (!wrapper) {
        const uaelements = navigator.userAgent.split(' ')
        const wrapperElement = uaelements.find(e =>
            e.includes('booknookwrapper')
        )
        if (wrapperElement) {
            wrapper = wrapperElement.split('/')[1]
            if (!!wrapper) wrapper = wrapper.toLowerCase()
        }
    }

    return wrapper
}

export const USER_AGENT = new UAParser(navigator.userAgent).getResult()
export const IS_ANDROID = USER_AGENT.os.name === 'Android'
export const IS_CHROME_OS = USER_AGENT.os.name === 'Chromium OS'
export const IS_IOS = USER_AGENT.os.name === 'iOS'
export const IS_MOBILE = IS_ANDROID || IS_IOS

const WRAPPER = getWrapper()
export const IS_ANDROID_WRAPPER = WRAPPER === 'android'
export const IS_CHROME_OS_WRAPPER = WRAPPER === 'chromeos'
export const IS_IOS_WRAPPER = WRAPPER === 'ios'
export const IS_NATIVE_WRAPPER =
    IS_ANDROID_WRAPPER || IS_CHROME_OS_WRAPPER || IS_IOS_WRAPPER
export const IS_OAUTH = window.location.href.includes('oauth')
export const BROWSER_SUPPORTED =
    (USER_AGENT.browser.name === 'Chrome' &&
        parseInt(USER_AGENT.browser.major, 10) >= MIN_SUPPORTED_CHROME_MAJOR) ||
    (USER_AGENT.browser.name === 'Safari' &&
        parseInt(USER_AGENT.browser.major, 10) >= MIN_SUPPORTED_SAFARI_MAJOR) ||
    (USER_AGENT.browser.name === 'Mobile Safari' &&
        parseInt(USER_AGENT.browser.major, 10) >= MIN_SUPPORTED_SAFARI_MAJOR) ||
    (USER_AGENT.browser.name === 'WebKit' &&
        parseInt(USER_AGENT.browser.major, 10) >= MIN_SUPPORTED_WEBKIT_MAJOR) ||
    (USER_AGENT.browser.name === 'Android Browser' &&
        parseInt(USER_AGENT.browser.major, 10) >=
            MIN_SUPPORTED_ANDROID_BROWSER_MAJOR) ||
    (USER_AGENT.browser.name === 'Chrome WebView' &&
        parseInt(USER_AGENT.browser.major, 10) >= MIN_SUPPORTED_CHROME_MAJOR) ||
    (USER_AGENT.browser.name === 'Edge' &&
        parseInt(USER_AGENT.browser.major, 10) >= MIN_SUPPORTED_CHROME_MAJOR)
export const ENVIRONMENT = parseEnvironment()
export const VERSION = VersionData.version
export function isDevelopment() {
    return ENVIRONMENT === ENVIRONMENT_DEVELOPMENT
}
export function isProduction() {
    return ENVIRONMENT === ENVIRONMENT_PRODUCTION
}
export function isQA() {
    return ENVIRONMENT === ENVIRONMENT_QA
}
export function isDemo() {
    return ENVIRONMENT === ENVIRONMENT_DEMO
}
export function isStaging() {
    return ENVIRONMENT === ENVIRONMENT_STAGING
}
export function isTutoringQA() {
    return ENVIRONMENT === ENVIRONMENT_TUTORING_QA
}
export function isTutoringProduction() {
    return ENVIRONMENT === ENVIRONMENT_TUTORING_PROD
}

let baseApiUrl, raygunKey, googleAnalyticsKey, firebaseConfig

if (isProduction()) {
    baseApiUrl = API_URL_PRODUCTION
    raygunKey = RAYGUN_KEY_PRODUCTION
    googleAnalyticsKey = GOOGLE_ANALYTICS_KEY_PROD
    firebaseConfig = {
        apiKey: 'AIzaSyCpJloaSO3llZvEPc4RKOMI1r1JQzkzfgc',
        authDomain: 'booknook-prod.firebaseapp.com',
        databaseURL: 'https://booknook-prod.firebaseio.com',
        projectId: 'booknook-prod',
        storageBucket: 'booknook-prod.appspot.com',
        messagingSenderId: '111841019492',
    }
} else if (isQA()) {
    baseApiUrl = API_URL_QA
    raygunKey = RAYGUN_KEY_QA
    googleAnalyticsKey = GOOGLE_ANALYTICS_KEY_QA
    firebaseConfig = {
        apiKey: 'AIzaSyCO-6DVcXM2OYvbuEwBljDxyakBhSD6Ppc',
        authDomain: 'booknook-qa.firebaseapp.com',
        databaseURL: 'https://booknook-qa.firebaseio.com',
        projectId: 'booknook-qa',
        storageBucket: 'booknook-qa.appspot.com',
        messagingSenderId: '811595540567',
    }
} else if (isDemo()) {
    baseApiUrl = API_URL_DEMO
    firebaseConfig = {
        apiKey: 'AIzaSyDkzdgD2Owk6gKrMAn6fVLNoCqfJ5N416Y',
        authDomain: 'booknook-demo.firebaseapp.com',
        databaseURL: 'https://booknook-demo.firebaseio.com',
        projectId: 'booknook-demo',
        storageBucket: 'booknook-qa.appspot.com',
        messagingSenderId: '990207024766',
    }
} else if (isStaging()) {
    baseApiUrl = API_URL_STAGING
    raygunKey = RAYGUN_KEY_STAGING
    firebaseConfig = {
        apiKey: 'AIzaSyC3YHmQS8xhq83eVswiymnXYSf4WPdD1og',
        authDomain: 'booknook-staging.firebaseapp.com',
        databaseURL: 'https://booknook-staging.firebaseio.com',
        projectId: 'booknook-staging',
        storageBucket: 'booknook-staging.appspot.com',
        messagingSenderId: '738971504848',
    }
} else if (isTutoringQA()) {
    baseApiUrl = API_URL_TUTORING_QA
    raygunKey = undefined
    firebaseConfig = {
        apiKey: 'AIzaSyDCNfhkod2N5foImrGT1zrXygOUzCsQaQA',
        authDomain: 'booknook-tutoring-qa.firebaseapp.com',
        projectId: 'booknook-tutoring-qa',
        storageBucket: 'booknook-tutoring-qa.appspot.com',
        messagingSenderId: '730794174394',
        appId: '1:730794174394:web:8cda20bbc18050eb87df83',
    }
} else if (isTutoringProduction()) {
    baseApiUrl = API_URL_TUTORING_PRODUCTION
    raygunKey = undefined
    firebaseConfig = {
        apiKey: 'AIzaSyD-_Z2lyJ8GRUsLJa2OT8Kt9gCTOylrAEo',
        authDomain: 'booknook-tutoring-prod.firebaseapp.com',
        projectId: 'booknook-tutoring-prod',
        storageBucket: 'booknook-tutoring-prod.appspot.com',
        messagingSenderId: '219070499250',
        appId: '1:219070499250:web:0f4ba93823f23704a6fcab',
    }
} else if (isDevelopment()) {
    baseApiUrl = API_URL_DEVELOPMENT
    raygunKey = undefined
    firebaseConfig = {
        apiKey: 'AIzaSyDQWUdEvnkZ3yoSavlYk12XYPnX4z-5uG0',
        authDomain: 'booknook-dev.firebaseapp.com',
        databaseURL: 'https://booknook-dev.firebaseio.com',
        projectId: 'booknook-dev',
        storageBucket: 'booknook-dev.appspot.com',
        messagingSenderId: '294015509573',
    }
}

export const FIREBASE_CONFIG = firebaseConfig
export const BASE_API_URL = baseApiUrl
export const RAYGUN_KEY = raygunKey
export const GOOGLE_ANALYTICS_KEY = googleAnalyticsKey
export const SALESFORCE_ORG_ID = '00D6A000002jmIT'
export const UPDATE_POLLING_INTERVAL = 1000 * 60 * 60 // 1 Hour

export const ZENDESK_FORM_URL =
    'https://support.booknooklearning.com/hc/en-us/requests/new?ticket_form_id=360001407711'

export const FORCE_BROWSER =
    isProduction() && getQueryParameter('force_browser') === 'true'
