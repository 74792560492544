import {
    CLASSROOM_COMPREHENSION_BOOK_INTRO,
    GUIDED_COMPREHENSION_BOOK_INTRO,
    K_ONE_BOOK_INTRO,
    K_ONE_BOOK_MAIN,
} from 'components/session-common/session-component-manager/checkpoints'
import { BOOK_ACTIVITY_TYPE } from './activity-types'
import {
    COMPREHENSION_LESSON_TYPE,
    FLUENCY_LESSON_TYPE,
    K_ONE_LESSON_TYPE,
} from './lesson-types'
import logger from './logger'
import { CLASSROOM_SESSION_TYPE, GUIDED_SESSION_TYPE } from './session-types'
import { USER_TYPE } from './user'

/**
 * Rejoin and related functions adds custom functionality to rejoining a session.
 * Given a condition of the session, one can reset the checkpoint of the tutor and
 * student.
 */
export const onRejoin = ({
    session,
    setCheckpoint,
    setActivityCheckpoint,
    userType,
    student,
}) => {
    const sessionType = session.session_type
    const lesson = session.lesson
    const lessonType = lesson && lesson.lesson_type
    switch (userType) {
        case USER_TYPE.STUDENT:
            handleStudentRejoin({
                sessionType,
                lessonType,
                setCheckpoint,
                session,
                student,
            })
            break
        case USER_TYPE.TUTOR:
            handleTutorRejoin({
                sessionType,
                lessonType,
                setCheckpoint,
                setActivityCheckpoint,
                session,
            })
            break
        default:
            break
    }
}

const handleStudentRejoin = ({
    sessionType,
    lessonType,
    setCheckpoint,
    session,
    student,
}) => {
    if (!sessionType) {
        logger.log(
            `sessionType is null for student: ${
                student.id
            } lessonType: ${lessonType} session: ${session && session.id}`
        )
    }
    switch (sessionType.code) {
        case CLASSROOM_SESSION_TYPE:
            switch (lessonType.code) {
                case COMPREHENSION_LESSON_TYPE:
                    if (session.state.hasSeenBook) {
                        student &&
                            setCheckpoint(
                                session,
                                student.id,
                                CLASSROOM_COMPREHENSION_BOOK_INTRO,
                                { useSnapshot: true }
                            )
                    }
                    break
                default:
                    break
            }
            break
        default:
            break
    }
}

const handleTutorRejoin = ({
    sessionType,
    lessonType,
    setCheckpoint,
    setActivityCheckpoint,
    session,
}) => {
    switch (sessionType.code) {
        case GUIDED_SESSION_TYPE:
            switch (lessonType.code) {
                case COMPREHENSION_LESSON_TYPE:
                    if (session.state.hasSeenBook) {
                        setCheckpoint(
                            session,
                            GUIDED_COMPREHENSION_BOOK_INTRO,
                            { useSnapshot: true }
                        )
                    }
                    break
                case K_ONE_LESSON_TYPE:
                    const bookActivity = findBookActivity(
                        session.lesson ? session.lesson.activities : []
                    )
                    if (
                        session.state.hasSeenBook &&
                        bookActivity &&
                        bookActivity.id
                    ) {
                        setActivityCheckpoint(
                            session,
                            K_ONE_BOOK_INTRO,
                            bookActivity.id,
                            { useSnapshot: true }
                        )
                    }
                    break
                case FLUENCY_LESSON_TYPE:
                    break
                default:
                    break
            }
            break
        case CLASSROOM_SESSION_TYPE:
            break
        default:
            break
    }
}

const findBookActivity = activities => {
    return activities.find(
        activity => activity.activity_type.code === BOOK_ACTIVITY_TYPE
    )
}

const BOOK_CHECKPOINTS = [
    GUIDED_COMPREHENSION_BOOK_INTRO,
    K_ONE_BOOK_MAIN,
    CLASSROOM_COMPREHENSION_BOOK_INTRO,
]

export const isBookCheckpoint = checkpointId => {
    return Boolean(
        BOOK_CHECKPOINTS.find(bookCheckpoint => bookCheckpoint === checkpointId)
    )
}
