import React, { useState } from 'react'

import styles from './TermsOfServicePopup.css'
import Text from 'components/text'
import { PrimaryButton } from 'components/session-common/action-bar'
import { TermsOfService } from 'components/terms-of-service'
import tosMonster from './images/tos-monster.png'
import { GridLayout } from 'components/grid-layout'
import PropTypes from 'prop-types'
import { objKeysToSnakeCase } from 'util/misc'

const propTypes = {
    tutor: PropTypes.object.isRequired,
    updateAccount: PropTypes.func.isRequired,
}

export const TermsOfServicePopup = ({ tutor, updateAccount }) => {
    const [acceptedChecked, setAcceptedChecked] = useState(false)

    const handleChange = event => {
        setAcceptedChecked(event.target.checked)
    }

    const handleSubmit = event => {
        updateAccount({
            ...objKeysToSnakeCase(tutor),
            accepted_terms_of_service: acceptedChecked,
        })
    }

    return (
        <GridLayout
            gap={{
                row: '1rem',
            }}
            classes={[styles.root]}
        >
            <img
                src={tosMonster}
                alt="monster"
                className={styles.monstersImg}
            />
            <Text
                size={'2rem'}
                weight={'bold'}
                font={'Montserrat'}
                color={'#444444'}
            >
                We've Updated Our Terms Of Service.
            </Text>
            <div className={styles.termsOfServiceWrapper}>
                <TermsOfService />
            </div>
            <div className={styles.acceptWrapper}>
                <input
                    onChange={handleChange}
                    type={'checkbox'}
                    data-testid={'accept-checkbox'}
                />
                <Text size={'1rem'} font={'Montserrat'} color={'#7C7C7C'}>
                    I agree to the terms of service.
                </Text>
            </div>
            <PrimaryButton
                text={'Continue'}
                enabled={acceptedChecked}
                onClick={handleSubmit}
            />
        </GridLayout>
    )
}

TermsOfServicePopup.propTypes = propTypes
