import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import onClickOutside from 'react-onclickoutside'
import styles from './index.css'

function AdminTutoringMenu() {
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false)

    AdminTutoringMenu.handleClickOutside = () => {
        setIsOpen(false)
    }

    const onMenuClick = event => {
        toggleMenu()
        event.stopPropagation()
    }
    const toggleMenu = () => setIsOpen(prev => !prev)
    const onNav = url => history.push(url)

    return (
        <div className={styles.menu} onClick={onMenuClick} onBlur={onMenuClick}>
            <div
                className={`${styles.tab} ${
                    isOpen ? `${styles.tabActive}` : ''
                }`}
            >
                Tutoring Admin
            </div>
            {isOpen && (
                <div className={styles.popup}>
                    <div
                        className={styles.menuItem}
                        onClick={() => onNav('/admin/tutoring/programs')}
                    >
                        Programs
                    </div>
                    <div
                        className={styles.menuItem}
                        onClick={() => onNav('/admin/tutoring/timeblocks')}
                    >
                        Time Blocks
                    </div>
                </div>
            )}
        </div>
    )
}

const clickOutsideConfig = {
    handleClickOutside: () => AdminTutoringMenu.handleClickOutside,
}

export default onClickOutside(AdminTutoringMenu, clickOutsideConfig)
