import { SESSION_GET_TUTOR } from 'actions'

const initialState = {
    sessionTutor: {},
}

export default (state = initialState, action) => {
    if (action.error) return state

    switch (action.type) {
        case SESSION_GET_TUTOR:
            return {
                ...state,
                sessionTutor: action.payload,
            }
        default:
            return state
    }
}
