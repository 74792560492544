// Example.com or bnX@booknooklearning.com signifies a faked email address
// that we assigned to a VPIKid tutor
// This can be used to disable certain functionality where we know the email is
// not reachable by the tutor, such as the "need help?" button
export const isExternalTutor = tutor => {
    if (!tutor) {
        return false
    }
    const payload = tutor.tutorType || tutor.tutor_type
    return payload?.name === 'External Tutor'
}
