import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Nav from './nav/Nav'
import logo from './images/header-logo.svg'
import styles from './index.css'
import FreeTrialBanner from 'components/free-trial-banner'

class Header extends Component {
    render() {
        const { tutor } = this.props
        let logoComponent = (
            <div className={styles.logo}>
                <img src={logo} alt="" />
            </div>
        )
        if (!this.props.session || !this.props.student) {
            logoComponent = <Link to="/">{logoComponent}</Link>
        }

        return (
            <div className={styles.headerWrapper}>
                {tutor && tutor.freeTrial ? <FreeTrialBanner /> : null}
                <div className={styles.header}>
                    {logoComponent}
                    <Nav isTutoringMenu={this.props.isTutoringMenu} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        session: state.activeSession,
        student: state.student,
        tutor: state.tutor,
    }
}

export default connect(mapStateToProps)(Header)
