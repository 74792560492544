import PropTypes from 'prop-types'
import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { openPopup, closePopup } from 'actions/popup'
import { setCheckpoint } from 'actions/session'
import { logout } from 'actions/auth'
import { WRAP_UP_COMPLETE } from 'components/session-common/session-component-manager/checkpoints'
import { bindActionCreators } from 'redux'
import Prompt from 'components/session-common/prompt'
import mergePropsWithOverrides from 'util/merge-props-with-overrides'

import styles from './index.css'

class Menu extends Component {
    static displayName = 'Menu'

    constructor() {
        super()
        this.state = { open: false }
        this.toggleMenu = this.toggleMenu.bind(this)
        this.onWindowClick = this.onWindowClick.bind(this)
        this.onMenuClick = this.onMenuClick.bind(this)
        this.onEndSessionClicked = this.onEndSessionClicked.bind(this)
        this.onEndSessionConfirmed = this.onEndSessionConfirmed.bind(this)
        this.onSignOutClicked = this.onSignOutClicked.bind(this)
        this.onSignOutConfirmed = this.onSignOutConfirmed.bind(this)
    }

    handleClickOutside() {
        this.onWindowClick()
    }

    onMenuClick(event) {
        if (this.props.goHome) {
            this.props.history.push('/')
        } else {
            this.toggleMenu()
        }
        event.stopPropagation()
    }

    onWindowClick() {
        this.setState({ open: false })
    }

    toggleMenu() {
        this.setState({ open: !this.state.open })
    }

    onEndSessionClicked() {
        const popup = (
            <Prompt
                primaryButtonText="Yes, end the session"
                secondaryButtonText="Oops, just kidding"
                onPrimaryClicked={this.onEndSessionConfirmed}
                onSecondaryClicked={this.props.closePopup}
            >
                Do you really want to end the current session?
            </Prompt>
        )
        this.props.openPopup(popup)
    }

    onEndSessionConfirmed() {
        this.props.closePopup()
        this.props.setCheckpoint(this.props.session, WRAP_UP_COMPLETE)
    }

    onSignOutClicked() {
        const popup = (
            <Prompt
                primaryButtonText="Yes, sign me out"
                secondaryButtonText="Oops, just kidding"
                onPrimaryClicked={this.onSignOutConfirmed}
                onSecondaryClicked={this.props.closePopup}
            >
                You’re about to sign out of BookNook. <br />
                This will end your current session. <br />
                <br />
                Do you really want to sign out?
            </Prompt>
        )
        this.props.openPopup(popup)
    }

    onSignOutConfirmed() {
        this.props.closePopup()
        const redirect = this.props.tutor ? '/tutor/login' : '/student/login'
        this.props.logout()
        this.props.history.push(redirect)
    }

    render() {
        const style = this.state.open ? styles.open : styles.closed

        return (
            <div
                onClick={this.onMenuClick}
                className={`${styles.root} ${style}`}
            >
                <div className={styles.menuWrapper}>
                    {!!this.props.tutor && !!this.props.session && (
                        <div
                            onClick={this.onEndSessionClicked}
                            className={styles.menuButton}
                        >
                            <div className={styles.triangle} />
                            End session
                        </div>
                    )}
                    <div
                        onClick={this.onSignOutClicked}
                        className={styles.menuButton}
                    >
                        <div className={styles.triangle} />
                        Sign out
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        tutor: state.tutor,
        student: state.student,
        session: state.activeSession,
    }
}

const mapDispatch = dispatch => {
    return bindActionCreators(
        { openPopup, closePopup, setCheckpoint, logout },
        dispatch
    )
}

Menu.propTypes = {
    goHome: PropTypes.bool.isRequired,
}

Menu.defaultProps = {
    goHome: false,
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatch,
        mergePropsWithOverrides(Menu)
    )(onClickOutside(Menu))
)
