import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { parse } from 'query-string'

import BaseComponent, { mapDispatch } from 'components/base-component'
import { requestPasswordReset } from 'actions/tutor'
import { authenticateTutor } from 'actions/auth'
import { openAlert } from 'actions/popup'
import { setBackgroundColor, resetAppLayout } from 'actions/app-layout'
import { checkForUpdates } from 'actions/app'

import Form from './form'

import styles from './index.css'

class Login extends BaseComponent {
    constructor(props) {
        super(props)

        const { email } = parse(props.location.search)

        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onForgotPassword = this.onForgotPassword.bind(this)
        this.onEmailChanged = this.onEmailChanged.bind(this)
        this.state = {
            email,
            emailValid: false,
        }
    }

    componentDidMount() {
        this.props.checkForUpdates()
    }

    componentDidUpdate() {
        const query = parse(this.props.location.search)
        if (!!this.props.tutor) {
            if (this.props.tutor.forcePasswordChange) {
                if (query.redirect) {
                    this.props.history.push(
                        `/tutor/onboarding?redirect=${query.redirect}`
                    )
                } else {
                    this.props.history.push('/tutor/onboarding')
                }
            } else if (query.redirect) {
                this.props.history.push(query.redirect)
            } else {
                this.props.history.push('/')
            }
        }
    }

    onFormSubmit(formValid, formFields) {
        this.props.authenticateTutor(formFields.email, formFields.password)
    }

    onEmailChanged(email, emailValid) {
        this.setState({ email, emailValid })
    }

    onForgotPassword(email) {
        if (this.state.emailValid && !this.state.isRequestActive) {
            this.startRequests([
                this.props.requestPasswordReset(this.state.email.toLowerCase()),
            ]).then(() => {
                this.props.openAlert(
                    'Check your email for instructions to reset your password.'
                )
            })
        }
    }

    render() {
        let forgotPasswordClass
        if (!this.state.emailValid) forgotPasswordClass = styles.disabled

        return (
            <div className={styles.root}>
                <div className={styles.header}>Welcome to BookNook!</div>
                <div className={styles.subheader}>
                    Enter your email and password to sign in.
                </div>
                <div className={styles.form}>
                    <Form
                        onFormSubmit={this.onFormSubmit}
                        initialEmailValue={this.state.email}
                        onEmailChanged={this.onEmailChanged}
                        isFormBusy={this.state.isRequestActive}
                    />
                </div>
                <div className={styles.footer}>
                    <Link className={styles.action} to="/student/login">
                        Sign in as Student
                    </Link>{' '}
                    <div className={styles.seperator} />{' '}
                    <div
                        className={`${styles.action} ${forgotPasswordClass}`}
                        onClick={this.onForgotPassword}
                    >
                        Forgot password?
                    </div>
                    <div className={styles.legal}>
                        By signing in, you are agreeing to BookNook's{' '}
                        <a
                            href="https://www.booknooklearning.com/tos/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms of Service
                        </a>{' '}
                        and{' '}
                        <a
                            href="https://www.booknooklearning.com/privacy/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                        .
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        tutor: state.tutor,
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatch({
            checkForUpdates,
            authenticateTutor,
            requestPasswordReset,
            openAlert,
            setBackgroundColor,
            resetAppLayout,
        })
    )(Login)
)
