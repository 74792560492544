import React from 'react'
import { connect } from 'react-redux'
import { parse, stringify } from 'query-string'
import { withRouter } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import Auth from 'util/auth'

import BaseComponent, { mapDispatch } from 'components/base-component'
import { closePopup } from 'actions/popup'
import { initialize, initializeStudent } from 'actions/initialize'
import { logout } from 'actions/auth'
import classNames from 'classnames'

import { IS_MOBILE, IS_CHROME_OS } from 'config'
import Forms from 'components/forms'
import Header from './header'
import UpdateWarning from './update-warning'
import PortraitWarning from './portrait-warning'
import OnlineIndicator from './online-indicator'
import FreeTrialExpiredPopup from './free-trial-expired-popup'
import ErrorNotification from './error-notification'
import ToastManager from './toast-manager'

import styles from './index.css'
import TermsOfServicePopup from 'components/terms-of-service-popup'
import { checkForUpdates } from 'actions/app'
import { UPDATE_POLLING_INTERVAL } from 'config'

const APP_UPDATE_TIMEOUT = 3 * 1000

class AppContainer extends BaseComponent {
    constructor() {
        super()
        this.state = {
            loaded: false,
            showPortraitWarning: false,
        }
        this.onPopupClosed = this.onPopupClosed.bind(this)
        this.onWindowResized = this.onWindowResized.bind(this)
        this.updateInterval = null
    }

    componentWillUnmount() {
        clearInterval(this.updateInterval)
    }

    componentDidMount() {
        this.updateInterval = setInterval(() => {
            this.props.checkForUpdates()
        }, UPDATE_POLLING_INTERVAL)

        if (IS_MOBILE || IS_CHROME_OS) window.onresize = this.onWindowResized

        const showPortraitWarning = this.shouldShowPortraitWarning()
        if (Auth.isTutorLoggedIn()) {
            this.startRequests([this.props.initialize()])
                .then(() => {
                    this.setState({ loaded: true, showPortraitWarning })
                    const query = parse(this.props.location.search)
                    if (this.props.tutor.forcePasswordChange) {
                        if (query.redirect) {
                            this.props.history.push(
                                `/tutor/onboarding?redirect=${query.redirect}`
                            )
                        } else {
                            this.props.history.push('/tutor/onboarding')
                        }
                    }
                })
                .catch(() => {
                    this.props.logout()
                    window.location.reload()
                })
        } else if (Auth.isStudentLoggedIn()) {
            this.startRequests([this.props.initializeStudent()]).then(() => {
                this.setState({ loaded: true, showPortraitWarning })
            })
        } else {
            this.setState({ loaded: true, showPortraitWarning })
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.layout.portraitWarningsEnabled !==
            prevProps.layout.portraitWarningsEnabled
        ) {
            this.setState({
                showPortraitWarning: this.shouldShowPortraitWarning(
                    this.props.layout.portraitWarningsEnabled
                ),
            })
        }
    }

    shouldShowPortraitWarning(portraitWarningsEnabled) {
        if (portraitWarningsEnabled === undefined)
            portraitWarningsEnabled = this.props.layout.portraitWarningsEnabled
        return (
            (IS_MOBILE || IS_CHROME_OS) &&
            portraitWarningsEnabled &&
            window.innerHeight > window.innerWidth
        )
    }

    onWindowResized() {
        this.setState({ showPortraitWarning: this.shouldShowPortraitWarning() })
    }

    onPopupClosed() {
        if (this.props.popup.onClose) {
            this.props.popup.onClose()
        }
        this.props.closePopup()
    }

    onPortraitWarningDismissed() {
        this.setState({ showPortraitWarning: false })
    }

    render() {
        if (!this.state.loaded) return <div />

        const acceptedTermsOfService = this.props.tutor
            ? this.props.tutor.acceptedTermsOfService
            : true

        let popupContent = this.props.popup.popupContent
        if (this.state.showPortraitWarning) {
            popupContent = (
                <PortraitWarning onClosed={this.onPortraitWarningDismissed} />
            )
        } else if (this.props.popup.alertContent) {
            popupContent = (
                <div className={styles.popupAlert}>
                    <div>{this.props.popup.alertContent}</div>
                    <div className={styles.popupFooter}>
                        <Forms.SubmitButton
                            content="OK"
                            onClick={this.onPopupClosed}
                        />
                    </div>
                </div>
            )
        } else if (!acceptedTermsOfService) {
            popupContent = <TermsOfServicePopup />
        }

        let query = parse(this.props.location.search)

        const reloadPage = () => {
            query.reloaded = true
            window.location.search = stringify(query) // Refresh page and add query param
        }

        if (this.props.app.update && !query.reloaded) {
            setTimeout(reloadPage, APP_UPDATE_TIMEOUT)

            popupContent = <UpdateWarning />
        }

        let popup
        if (popupContent) {
            popup = (
                <div className={styles.popupBackground}>
                    <div
                        className={classNames(
                            styles.popupWindow,
                            acceptedTermsOfService
                                ? null
                                : styles.popupWindowTermsOfService
                        )}
                        style={this.props.popup.popupWindowStyle}
                    >
                        <div style={this.props.popup.popupStyle}>
                            {popupContent}
                        </div>
                    </div>
                </div>
            )
        }

        let appStyle = {}
        if (this.props.layout.backgroundColor) {
            appStyle.background = this.props.layout.backgroundColor
        }

        let regexTutoring = new RegExp(`(/admin/tutoring/)`)
        let location = this.props.history.location.pathname

        let header
        let contentStyle = {}
        if (this.props.layout.hideMenu) {
            contentStyle.height = '100%'
        } else {
            header = <Header isTutoringMenu={location.match(regexTutoring)} />
        }

        return (
            <div className={styles.app} style={appStyle}>
                {header}
                <div className={styles.content} style={contentStyle}>
                    {this.props.children}
                </div>
                {popup}
                <FreeTrialExpiredPopup />
                <ErrorNotification />
                <ToastProvider autoDismissTimeout={3000} autoDismiss>
                    <ToastManager />
                </ToastProvider>
                <OnlineIndicator />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        app: state.app,
        tutor: state.tutor,
        popup: state.popup,
        content: state.popup.popupContent,
        layout: state.appLayout,
    }
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatch({
            closePopup,
            initialize,
            initializeStudent,
            logout,
            checkForUpdates,
        })
    )(AppContainer)
)
