import PropTypes from 'prop-types'
import React, { Component } from 'react'
import GenericFormField from '../generic-form-field'
import styles from './index.css'

class SelectFormField extends Component {
    state = { value: this.props.initialValue, valid: false }

    static defaultProps = {
        isRequired: true,
        items: [],
    }

    static propTypes = {
        initialValue: PropTypes.node,
        isRequired: PropTypes.bool,
        items: PropTypes.array,
        label: PropTypes.string,
        onChange: PropTypes.func,
        'data-testid': PropTypes.string,
    }

    onFieldValueChanged = event => {
        const { value } = event.target
        this.changeFieldValue(value)
    }

    changeFieldValue = newValue => {
        const isValid =
            !this.props.isRequired ||
            (newValue !== null && newValue !== undefined) ||
            (!this.props.onValidateFieldValue ||
                this.props.onValidateFieldValue(newValue))
        this.setState({ value: newValue, valid: isValid })
        this.props.onChange && this.props.onChange(newValue, isValid)
    }

    render() {
        return (
            <GenericFormField
                label={this.props.label}
                isRequired={this.props.isRequired}
                style={this.props.style}
                hint={this.props.hint}
                id={this.props.id}
            >
                <select
                    data-testid={this.props['data-testid']}
                    className={styles.select}
                    value={this.state.value}
                    onChange={this.onFieldValueChanged}
                >
                    {this.props.items.map(i => (
                        <option
                            key={i.id}
                            data-testid={`option-${i.id}`}
                            id={i.id}
                            value={i.id}
                        >
                            {i.name}
                        </option>
                    ))}
                </select>
            </GenericFormField>
        )
    }
}

export default SelectFormField
