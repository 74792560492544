import FirebaseManager from 'util/firebase-manager'

// When a tutor joins a session, update the status for the session's students
// in firebase so that they will get redirected into the session.

class SessionActiveBroadcaster {
    constructor(session) {
        this.session = session

        this.studentDatabaseRefs = session.students.map(student => ({
            id: student.id,
            ref: FirebaseManager.firebase
                .database()
                .ref(`/students/${student.id}`),
        }))

        this.studentFirestoreRefs = session.students.map(student => ({
            id: student.id,
            ref: FirebaseManager.firebase
                .firestore()
                .doc(`/students/${student.id}`),
        }))

        this.isActiveForDatabase = {
            activeSessionId: session.id,
            lastChanged:
                FirebaseManager.firebase.database.ServerValue.TIMESTAMP,
        }

        this.isActiveForFirestore = {
            activeSessionId: session.id,
            lastChanged: FirebaseManager.firebase.firestore.FieldValue.serverTimestamp(),
        }

        this.isNotActiveForDatabase = {
            activeSessionId: 'none',
            lastChanged:
                FirebaseManager.firebase.database.ServerValue.TIMESTAMP,
        }

        this.isNotActiveForFirestore = {
            activeSessionId: 'none',
            lastChanged: FirebaseManager.firebase.firestore.FieldValue.serverTimestamp(),
        }
    }

    // https://cloud.google.com/firestore/docs/solutions/presence
    // Implements a presense system using firebase realtime database,
    // and hook it up to our firestore using cloud functions.
    broadcast() {
        // Create a reference to the special '.info/connected' path in
        // Realtime Database. This path returns `true` when connected
        // and `false` when disconnected.
        FirebaseManager.firebase
            .database()
            .ref('.info/connected')
            .on('value', snapshot => {
                // If we're not currently connected, don't do anything.
                if (snapshot.val() === false) {
                    this.studentFirestoreRefs.map(({ id, ref }) =>
                        ref.set(this.isNotActiveForFirestore, { merge: true })
                    )
                    return
                }
                this.studentDatabaseRefs.forEach(({ id, ref }) => {
                    ref.onDisconnect()
                        .set(this.isNotActiveForDatabase)
                        .then(() => {
                            ref.set(this.isActiveForDatabase)

                            this.studentFirestoreRefs
                                .find(ref => ref.id === id)
                                .ref.set(this.isActiveForFirestore, {
                                    merge: true,
                                })
                        })
                })
            })
    }

    setActive() {
        // Realtime database does not offer a merge option, so enable the merge
        // option in the cloud function that syncs realtime databse to firestore
        this.studentDatabaseRefs.map(({ id, ref }) =>
            ref.set(this.isActiveForDatabase)
        )
        this.studentFirestoreRefs.map(({ id, ref }) =>
            ref.set(this.isActiveForFirestore, { merge: true })
        )
    }

    setInactive() {
        this.studentDatabaseRefs.map(({ id, ref }) =>
            ref.set(this.isNotActiveForDatabase)
        )
        this.studentFirestoreRefs.map(({ id, ref }) =>
            ref.set(this.isNotActiveForFirestore, { merge: true })
        )
        // TODO: Surface a method to get rid of the listeners
    }
}

export default SessionActiveBroadcaster
