import ApiClient from 'util/book-nook-api-client'
import { objKeysToSnakeCase } from 'util/misc'
import {
    TUTOR_UPDATE_ACCOUNT,
    TUTOR_CHANGE_PASSWORD,
    TUTOR_REQUEST_PASSWORD_RESET,
    TUTOR_RESET_PASSWORD,
    TUTOR_GET_TUTOR_TYPES,
    TUTOR_SET_ONBOARDED,
    TUTOR_SET_TRIED_DEMO_SESSION,
    TUTOR_SET_SHOW_FREE_TRIAL_TUTORIAL,
    TUTOR_RESET_FREE_TRIAL,
} from 'actions'

/**
 *
 * @param {TutorUpdate} tutor
 */
export function updateAccount(tutor) {
    const payload = ApiClient.updateTutor(tutor)

    return {
        type: TUTOR_UPDATE_ACCOUNT,
        payload,
    }
}

export function changePassword(id, password) {
    const payload = ApiClient.changeTutorPassword(id, password)

    return {
        type: TUTOR_CHANGE_PASSWORD,
        payload,
    }
}

export function requestPasswordReset(email) {
    const payload = ApiClient.requestTutorPasswordReset(email)

    return {
        type: TUTOR_REQUEST_PASSWORD_RESET,
        payload,
    }
}

export function resetPassword(token, password) {
    const payload = ApiClient.resetTutorPassword(token, password)

    return {
        type: TUTOR_RESET_PASSWORD,
        payload,
    }
}

export function getTutorTypes() {
    const payload = ApiClient.getTutorTypes()

    return {
        type: TUTOR_GET_TUTOR_TYPES,
        payload,
    }
}

export function requestFreeTrialExtension(values) {
    const body = objKeysToSnakeCase(values)
    const payload = ApiClient.requestFreeTrialExtension(body)

    return {
        type: TUTOR_UPDATE_ACCOUNT,
        payload,
    }
}

export function setOnboarded(tutorId, onboarded = true) {
    const payload = ApiClient.setTutorOnboarded(tutorId, onboarded)

    return {
        type: TUTOR_SET_ONBOARDED,
        payload,
    }
}

export function setTriedDemoSession(tutorId, triedDemo = true) {
    const payload = ApiClient.setTutorTriedDemoSession(tutorId, triedDemo)

    return {
        type: TUTOR_SET_TRIED_DEMO_SESSION,
        payload,
    }
}

export function setShowFreeTrialTutorial(show = false) {
    return {
        type: TUTOR_SET_SHOW_FREE_TRIAL_TUTORIAL,
        payload: show,
    }
}

export const resetFreeTrial = (tutorId, date) => {
    const payload = ApiClient.resetTutorFreeTrial(tutorId, date)

    return {
        type: TUTOR_RESET_FREE_TRIAL,
        payload,
    }
}
