import { lazy } from 'react'

const ActivateLesson = lazy(() => import('./lessons/activate'))
const Books = lazy(() => import('./books'))
const CreateBook = lazy(() => import('./books/CreateBook'))
const CreateLesson = lazy(() => import('./lessons/create'))
const CreateMultisite = lazy(() => import('./multisites/CreateMultisite'))
const CreatePassage = lazy(() => import('./passages/create'))
const CreateSchool = lazy(() => import('./schools/CreateSchool'))
const CreateStandard = lazy(() => import('./standards/create'))
const CreateStudent = lazy(() => import('./students/CreateStudent'))
const CreateTutor = lazy(() => import('./tutors/CreateTutor'))
const EditBook = lazy(() => import('./books/EditBook'))
const EditLesson = lazy(() => import('./lessons/edit'))
const EditMultisite = lazy(() => import('./multisites/EditMultisite'))
const EditMultisiteSchools = lazy(() =>
    import('./multisites/EditMultisiteSchools')
)
const EditPassage = lazy(() => import('./passages/edit'))
const EditSchool = lazy(() => import('./schools/EditSchool'))
const EditStandard = lazy(() => import('./standards/edit'))
const EditStudent = lazy(() => import('./students/EditStudent'))
const EditTutor = lazy(() => import('./tutors/EditTutor'))
const TutorProfile = lazy(() => import('./tutors/profile'))
const Lessons = lazy(() => import('./lessons'))
const ManageSchoolFeatures = lazy(() =>
    import('./schools/ManageSchoolFeatures')
)
const ManageSchoolPublishers = lazy(() =>
    import('./schools/ManageSchoolPublishers')
)
const Multisites = lazy(() => import('./multisites'))
const Passages = lazy(() => import('./passages'))
const PlatformMetrics = lazy(() => import('./platform-metrics'))
const PreviewBook = lazy(() => import('./books/PreviewBook'))
const SchoolMetrics = lazy(() => import('./school-metrics'))
const Schools = lazy(() => import('./schools/Schools'))
const Standards = lazy(() => import('./standards'))
const StoppingPoints = lazy(() => import('./stopping-points'))
const StudentDevices = lazy(() => import('./students/Devices'))
const Students = lazy(() => import('./students'))
const TutorAdminSchools = lazy(() => import('./tutors/admin-schools'))
const TutorDevices = lazy(() => import('./tutors/Devices'))
const TutorMultisites = lazy(() => import('./tutors/multisites'))
const TutorPublishers = lazy(() => import('./tutors/publishers'))
const Tutors = lazy(() => import('./tutors'))
const TutorSchools = lazy(() => import('./tutors/schools'))

const Tutoring = lazy(() => import('./manage-tutoring/home/Home'))

const ProgramsList = lazy(() =>
    import('./manage-tutoring/admin-program/ProgramList')
)
const NewProgram = lazy(() =>
    import('./manage-tutoring/admin-program/CreateProgram')
)
const EditProgram = lazy(() =>
    import('./manage-tutoring/admin-program/EditProgram')
)

const TimeblockList = lazy(() =>
    import('./manage-tutoring/timeblocks/TimeblockList')
)
const NewTimeblock = lazy(() =>
    import('./manage-tutoring/timeblocks/CreateTimeblock')
)
const EditTimeblock = lazy(() =>
    import('./manage-tutoring/timeblocks/EditTimeblock')
)
const ProgramManageTutor = lazy(() =>
    import('./manage-tutoring/admin-program/ManageTutor')
)
const ProgramManageStudent = lazy(() =>
    import('./manage-tutoring/admin-program/ManageStudent')
)
const ProgramManageTimeblock = lazy(() =>
    import('./manage-tutoring/admin-program/ManageTimeblock')
)
const ProgramStudentsList = lazy(() =>
    import('./manage-tutoring/program/StudentsList')
)
const ProgramStudentsDetails = lazy(() =>
    import('./manage-tutoring/program/Student')
)

const ProgramTutorsList = lazy(() =>
    import('./manage-tutoring/program/TutorsList')
)

const ProgramTutorDetails = lazy(() =>
    import('./manage-tutoring/program/Tutor')
)

const ImportStudents = lazy(() =>
    import('./manage-tutoring/admin-program/import/students/Students')
)

const ImportTutors = lazy(() =>
    import('./manage-tutoring/admin-program/import/tutors/Tutors')
)

const Overview = lazy(() => import('./manage-tutoring/overview/Overview'))

const TutoringGroups = lazy(() =>
    import('./manage-tutoring/tutoring-groups/TutoringGroupsList')
)

const TutoringGroupDetail = lazy(() =>
    import('./manage-tutoring/tutoring-groups/TutoringGroupDetail')
)

export default {
    ActivateLesson,
    Books,
    CreateBook,
    CreateLesson,
    CreateMultisite,
    CreatePassage,
    CreateSchool,
    CreateStandard,
    CreateStudent,
    CreateTutor,
    EditBook,
    EditLesson,
    EditMultisite,
    EditMultisiteSchools,
    EditPassage,
    EditSchool,
    EditStandard,
    EditStudent,
    EditTutor,
    TutorProfile,
    Lessons,
    ManageSchoolFeatures,
    ManageSchoolPublishers,
    Multisites,
    Passages,
    PlatformMetrics,
    PreviewBook,
    SchoolMetrics,
    Schools,
    Standards,
    StoppingPoints,
    StudentDevices,
    Students,
    TutorAdminSchools,
    TutorDevices,
    TutorMultisites,
    TutorPublishers,
    Tutors,
    TutorSchools,
    Tutoring,
    ProgramsList,
    NewProgram,
    EditProgram,
    TimeblockList,
    NewTimeblock,
    EditTimeblock,
    Overview,
    ProgramManageTutor,
    ProgramManageStudent,
    ProgramManageTimeblock,
    ProgramStudentsList,
    ProgramStudentsDetails,
    ProgramTutorsList,
    ProgramTutorDetails,
    TutoringGroups,
    TutoringGroupDetail,
    ImportStudents,
    ImportTutors,
}
