import {
    GET_LIST_PROGRAMS,
    GET_PROGRAM,
    CREATE_PROGRAM,
    UPDATE_PROGRAM,
    REMOVE_PROGRAM,
    INITIALIZE,
    GET_METRICS_PROGRAM,
    GET_PROGRAM_TIMEBLOCKS,
    GET_PROGRAM_STUDENTS,
    GET_PROGRAM_STUDENTS_COUNT,
    GET_PROGRAM_TUTORS,
    GET_PROGRAM_TUTORS_COUNT,
    GET_TUTORING_GROUPS,
    GET_TUTORING_GROUPS_COUNT,
    GET_TUTORING_GROUP,
    GET_TUTORING_GROUP_STUDENTS,
} from 'actions'

const initialState = {
    programs: [],
    program: {},
    createProgram: {},
    updateProgram: {},
    removeProgram: {},
    programTimeblocks: [],
    metrics: [],
    students: [],
    studentsCount: 0,
    tutors: [],
    tutorsCount: 0,
    tutoringGroups: null,
    tutoringGroupsCount: 0,
    tutoringGroup: {},
    tutoringGroupStudents: [],
}

export default (state = initialState, action) => {
    if (action.error) return state

    switch (action.type) {
        case GET_LIST_PROGRAMS:
            return {
                ...state,
                programs: action.payload,
            }
        case GET_PROGRAM:
            return {
                ...state,
                program: action.payload,
            }
        case CREATE_PROGRAM:
            return {
                ...state,
                createProgram: action.payload,
            }
        case UPDATE_PROGRAM:
            return {
                ...state,
                updateProgram: action.payload,
            }
        case REMOVE_PROGRAM:
            return {
                ...state,
                removeProgram: action.payload,
            }
        case GET_METRICS_PROGRAM:
            return {
                ...state,
                metrics: action.payload,
            }
        case GET_PROGRAM_TIMEBLOCKS:
            return {
                ...state,
                programTimeblocks: action.payload,
            }
        case INITIALIZE:
            return {
                ...initialState,
                isLoggedIn: true,
            }
        case GET_PROGRAM_STUDENTS:
            return {
                ...state,
                students: action.payload,
            }
        case GET_PROGRAM_STUDENTS_COUNT:
            return {
                ...state,
                studentsCount: action.payload,
            }
        case GET_PROGRAM_TUTORS:
            return {
                ...state,
                tutors: action.payload,
            }
        case GET_PROGRAM_TUTORS_COUNT:
            return {
                ...state,
                tutorsCount: action.payload,
            }
        case GET_TUTORING_GROUPS:
            return {
                ...state,
                tutoringGroups: action.payload,
            }
        case GET_TUTORING_GROUPS_COUNT:
            return {
                ...state,
                tutoringGroupsCount: action.payload,
            }

        case GET_TUTORING_GROUP:
            return {
                ...state,
                tutoringGroup: action.payload,
            }
        case GET_TUTORING_GROUP_STUDENTS:
            return {
                ...state,
                tutoringGroupStudents: action.payload,
            }
        default:
            return state
    }
}
