import React from 'react'
import Auth from 'util/auth'
import { parse } from 'query-string'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Root = ({ location }) => {
    const tutor = useSelector(state => state.tutor)
    const tutorSchools = useSelector(state => state.tutorSchools)

    if (Auth.isTutorLoggedIn()) {
        if (tutor.forcePasswordChange) {
            const query = parse(location.search)
            if (query.redirect) {
                return (
                    <Redirect
                        to={`/tutor/onboarding?redirect=${query.redirect}`}
                    />
                )
            } else {
                return <Redirect to="/tutor/onboarding" />
            }
        } else if (tutorSchools.length > 0) {
            let currentSchoolId = localStorage.getItem(
                'book-nook-tutor-current-school-id'
            )
            const defaultSchool =
                tutorSchools.find(
                    s => s.id === parseInt(currentSchoolId, 10)
                ) || tutorSchools[0]

            return <Redirect to={`/schools/${defaultSchool.id}`} />
        } else {
            return <Redirect to="/tutor/no_access" />
        }
    } else if (Auth.isStudentLoggedIn()) {
        return <Redirect to="/student" />
    } else {
        const query = parse(location.search)

        if (query.redirect) {
            return <Redirect to={`/tutor/login?redirect=${query.redirect}`} />
        } else {
            return <Redirect to="/student/login" />
        }
    }
}

export default Root
