export const SESSION_SETUP_STAGES = {
    PUBLISHER_SELECT: 'publisher_select',
    SESSION_TYPE_SELECT: 'session_type_select',
    STUDENT_SELECT: 'student_select',
    LESSON_SELECT: 'lesson_select',
}

export const ASSESSMENT_MODE = {
    TUTOR: 'TUTOR',
    STUDENT: 'STUDENT',
}

export const PROMPT_KIND = {
    WARNING: 'WARNING',
    NOTIFY: 'NOTIFY',
}

export const COOKIE_CLEVER_ID = 'COOKIE_CLEVER_ID'
