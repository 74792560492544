import Auth from 'util/auth'
import { objKeysToCamelCase } from 'util/misc'
import {
    AUTH_LOGOUT,
    AUTH_STUDENT,
    INITIALIZE_STUDENT,
    MANAGE_SCHOOL_IMPERSONATE_STUDENT,
    STUDENT_GET_GAME_HIGH_SCORES,
} from 'actions'

export default (state = null, action) => {
    if (action.error) return state

    switch (action.type) {
        case MANAGE_SCHOOL_IMPERSONATE_STUDENT:
        case AUTH_STUDENT:
        case INITIALIZE_STUDENT:
            return {
                ...objKeysToCamelCase(action.payload),
                isLoggedIn: true,
            }
        case AUTH_LOGOUT:
            Auth.logout()
            return null
        case STUDENT_GET_GAME_HIGH_SCORES:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
