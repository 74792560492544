import React from 'react'
import { IS_NATIVE_WRAPPER, ZENDESK_FORM_URL } from 'config'
import styles from './SmallDeviceAlert.css'

const SmallDeviceAlert = () => {
    const canSeeLink = !IS_NATIVE_WRAPPER

    return (
        <div className={styles.root}>
            <div className={styles.header} />
            <div className={styles.content}>
                <div className={styles.textLarge}>
                    Sorry, but we need some space.
                </div>
                <div className={styles.textSmall}>
                    If you’re seeing this error message, your device’s screen is
                    too small to properly display BookNook.
                </div>
                {canSeeLink && (
                    <a
                        href={ZENDESK_FORM_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Questions? Let us know.
                    </a>
                )}
            </div>
        </div>
    )
}

export default SmallDeviceAlert
