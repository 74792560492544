import axios from 'axios'
import Auth from 'util/auth'
import { BASE_API_URL } from 'config'
import { objKeysToSnakeCase } from './misc'

const DEFAULT_TIMEOUT = 30000
const REPORTING_TIMEOUT = 30000

let cancelGetAllTutors = null
let cancelGetTutorCount = null
let cancelGetStudentsCount = null
let cancelGetAllLessons = null

class BookNookApiClient {
    static createErrorLog(errors) {
        return this.post('/error_log', {
            application: 'booknook-app',
            errors,
        })
    }

    /**
     *
     * @param {TutorCreate} tutor
     */
    static createTutor(tutor) {
        return this.post('/tutors', {
            display_name: tutor.display_name,
            email: tutor.email,
            first_name: tutor.first_name,
            last_name: tutor.last_name,
            mobile_number: tutor.mobile_number,
            password: tutor.password,
            tutor_type_id: tutor.tutor_type_id,
            videoconferencing_link: tutor.videoconferencing_link,
            clever_id: tutor.clever_id,
        })
    }

    /**
     *
     * @param {TutorUpdate} tutor
     */
    static updateTutor(tutor) {
        return this.put(`/tutors/${tutor.id}`, {
            accepted_terms_of_service: tutor.accepted_terms_of_service,
            school_id: tutor.school_id,
            display_name: tutor.display_name,
            email: tutor.email,
            first_name: tutor.first_name,
            id: tutor.id,
            last_name: tutor.last_name,
            mobile_number: tutor.mobile_number,
            password: tutor.password,
            tutor_type_id: tutor.tutor_type_id,
            videoconferencing_link: tutor.videoconferencing_link,
            clever_id: tutor.clever_id,
        })
    }

    /**
     *
     * @param {TutorImport} tutor
     */
    static importTutor(tutor) {
        return this.post('/tutors/import', {
            display_name: tutor.display_name,
            email: tutor.email,
            first_name: tutor.first_name,
            last_name: tutor.last_name,
            mobile_number: tutor.mobile_number,
            password: tutor.password,
            school_id: tutor.school_id,
            tutor_type_id: tutor.tutor_type_id,
            videoconferencing_link: tutor.videoconferencing_link,
            clever_id: tutor.clever_id,
        })
    }

    /**
     *
     * @param {TutorUpdate} tutor
     */
    static updateTutorForSchool(tutor) {
        return this.put(`/schools/${tutor.school_id}/tutors/${tutor.id}`, {
            accepted_terms_of_service: tutor.accepted_terms_of_service,
            school_id: tutor.school_id,
            display_name: tutor.display_name,
            email: tutor.email,
            first_name: tutor.first_name,
            id: tutor.id,
            last_name: tutor.last_name,
            mobile_number: tutor.mobile_number,
            password: tutor.password,
            tutor_type_id: tutor.tutor_type_id,
            videoconferencing_link: tutor.videoconferencing_link,
            clever_id: tutor.clever_id,
        })
    }

    static removeTutorFromSchool(schoolId, tutorId) {
        return this.delete(`/schools/${schoolId}/tutors/${tutorId}`)
    }

    static getTutorProfile() {
        return this.get('tutors/profile')
    }

    static getTutorPublisherAdmin(id) {
        return this.get(`/tutors/${id}/publisher_admin_access`)
    }
    /**
     * Check if school admin
     * @param {number} schoolId
     * @param {number} tutorId
     */
    static isSchoolAdmin(schoolId, tutorId) {
        return this.get(`schools/${schoolId}/tutors/${tutorId}/is_school_admin`)
    }
    /**
     * check if school teacher
     * @param {string} schoolId
     * @param {string} tutorId
     */
    static isSchoolTeacher(schoolId, tutorId) {
        return this.get(
            `schools/${schoolId}/tutors/${tutorId}/is_school_teacher`
        )
    }

    static requestFreeTrialExtension(body) {
        return this.post(`/tutors/request_free_trial_extension`, body)
    }

    static approveTutorApplication(schoolId, tutorId) {
        return this.put(`/schools/${schoolId}/tutors/${tutorId}/approve`)
    }

    static rejectTutorApplication(schoolId, tutorId) {
        return this.put(`/schools/${schoolId}/tutors/${tutorId}/reject`)
    }

    static changeTutorPassword(id, password) {
        return this.put(`/tutors/${id}/password`, { password })
    }

    static setTutorOnboarded(id, onboarded) {
        return this.put(`/tutors/${id}/onboarded`, { onboarded })
    }

    static setTutorTriedDemoSession(id, triedDemo) {
        return this.put(`/tutors/${id}/tried_demo_session`, {
            tried_demo: triedDemo,
        })
    }

    static resetTutorFreeTrial(id, newDate) {
        const body = { expires_at: newDate }
        return this.post(`/tutors/${id}/extend_free_trial`, body)
    }

    static upgradeToPaid(id) {
        return this.put(`/tutors/${id}/upgrade_to_paid`, { id: id })
    }

    static getTutor(id) {
        return this.get(`/tutors/${id}`)
    }

    static getTutorForSchool(schoolId, tutorId) {
        return this.get(`/schools/${schoolId}/tutors/${tutorId}`)
    }

    static impersonateTutor(id) {
        return this.get(`/tutors/${id}/impersonate`)
    }

    static getAllTutors(offset, count, searchText) {
        if (cancelGetAllTutors) cancelGetAllTutors()

        let url = `/tutors?offset=${offset}&count=${count}`
        if (!!searchText) url += `&searchText=${searchText}`

        return this.get(
            url,
            null,
            DEFAULT_TIMEOUT,
            cancel => (cancelGetAllTutors = cancel)
        )
    }

    static getTutorCount(searchText) {
        if (cancelGetTutorCount) cancelGetTutorCount()

        let url = '/tutors/count'
        if (!!searchText) url += `?search_text=${searchText}`

        return this.get(
            url,
            null,
            DEFAULT_TIMEOUT,
            cancel => (cancelGetTutorCount = cancel)
        )
    }

    static getStudentsCount(searchText) {
        if (cancelGetStudentsCount) cancelGetStudentsCount()

        let url = '/students/count'
        if (!!searchText) url += `?search_text=${searchText}`

        return this.get(
            url,
            null,
            DEFAULT_TIMEOUT,
            cancel => (cancelGetStudentsCount = cancel)
        )
    }

    static authenticateFirebase() {
        return this.get('/firebase/auth')
    }

    static authenticateCleverOAuth(code) {
        return this.post('/clever/oauth', { code })
    }

    static authenticateTutor(email, password) {
        return this.post('/tutors/authenticate', { email, password })
    }

    static claimTutor(email) {
        return this.post('/tutors/claim', { email })
    }

    static activateTutor(tutorId) {
        return this.post(`/tutors/${tutorId}/activate`)
    }

    static deactivateTutor(tutorId) {
        return this.post(`/tutors/${tutorId}/deactivate`)
    }

    static makeAdmin(tutorId) {
        return this.put(`/tutors/${tutorId}/admin`)
    }

    static removeAdmin(tutorId) {
        return this.delete(`/tutors/${tutorId}/admin`)
    }

    static makePublisherAdmin(tutorId, publisherId) {
        return this.put(`/publishers/${publisherId}/tutors/${tutorId}/admin`)
    }

    static removePublisherAdmin(tutorId, publisherId) {
        return this.delete(`/publishers/${publisherId}/tutors/${tutorId}/admin`)
    }

    static makeSchoolAdmin(tutorId, schoolId) {
        return this.put(`/schools/${schoolId}/tutors/${tutorId}/admin`)
    }

    static removeSchoolAdmin(tutorId, schoolId) {
        return this.delete(`/schools/${schoolId}/tutors/${tutorId}/admin`)
    }

    static makeSchoolTeacher(tutorId, schoolId) {
        return this.put(`/schools/${schoolId}/tutors/${tutorId}/teacher`)
    }

    static removeSchoolTeacher(tutorId, schoolId) {
        return this.delete(`/schools/${schoolId}/tutors/${tutorId}/teacher`)
    }

    static getTutorStudents({
        tutorId,
        limit,
        offset,
        orderBy,
        orderDirection,
        searchText,
        gradeLevelId,
        readingLevelId,
    }) {
        let url = `/tutors/${tutorId}/students?`
        if (!!limit) url += `&limit=${limit}`
        if (!!offset) url += `&offset=${offset}`
        if (!!orderBy) url += `&order_by=${orderBy}`
        if (!!orderDirection) url += `&order_direction=${orderDirection}`
        if (!!searchText) url += `&search_text=${searchText}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`
        if (!!readingLevelId) url += `&reading_level_id=${readingLevelId}`
        return this.get(url)
    }

    static getTutorStudentsCount({
        tutorId,
        searchText,
        gradeLevelId,
        readingLevelId,
    }) {
        let url = `/tutors/${tutorId}/students/count?`
        if (!!searchText) url += `&search_text=${searchText}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`
        if (!!readingLevelId) url += `&reading_level_id=${readingLevelId}`
        return this.get(url)
    }

    static getTutorSchools({ tutorId, schoolAdmin, directAccess }) {
        let url = `/tutors/${tutorId}/schools?`
        if (schoolAdmin) {
            url = url + `school_admin=${schoolAdmin}&`
        }
        if (directAccess) {
            url = url + `direct_access=${directAccess}`
        }
        return this.get(url)
    }

    static getTutorMultisites({ tutorId }) {
        return this.get(`/tutors/${tutorId}/multisites`)
    }

    static getTutorStudentsAtSchool(tutorId, schoolId) {
        return this.get(`/schools/${schoolId}/tutors/${tutorId}/students`)
    }

    static addStudentAccess(tutorId, studentId) {
        return this.put(`/tutors/${tutorId}/students/${studentId}`)
    }

    static addStudentAccessBatch(tutorId, student_ids) {
        return this.post(`/tutors/${tutorId}/students/add_batch`, {
            student_ids,
        })
    }

    static removeStudentAccess(tutorId, studentId) {
        return this.delete(`/tutors/${tutorId}/students/${studentId}`)
    }

    static removeStudentAccessBatch(tutorId, student_ids) {
        return this.post(`/tutors/${tutorId}/students/remove_batch`, {
            student_ids,
        })
    }

    static impersonateStudent(id) {
        return this.get(`/students/${id}/impersonate`)
    }

    static addSchool(tutorId, schoolId) {
        return this.put(`/tutors/${tutorId}/schools`, { school_id: schoolId })
    }

    static requestTutorPasswordReset(email) {
        return this.post('/tutors/request_password_reset', { email })
    }

    static resetTutorPassword(token, password) {
        return this.post('/tutors/reset_password', { token, password })
    }

    /**
     *
     * @param {StudentCreate} student
     */
    static createStudent(student) {
        const body = objKeysToSnakeCase(student)
        if (!!body.sis_id && !!body.sis_id.trim())
            body.sis_id = body.sis_id.trim()
        return this.post('/students', body)
    }

    /**
     *
     * @param {StudentImport} student
     */
    static importStudent(studentProp) {
        const student = objKeysToSnakeCase(studentProp)
        return this.post('/students/import', {
            id: student.id,
            username: student.username,
            first_name: student.first_name,
            last_name: student.last_name,
            clever_id: student.clever_id,
            sis_id: student.sis_id,
            grade_level_id: student.grade_level_id,
            school_id: student.school_id,
            reading_level_id: student.reading_level_id,
            gender_type_id: student.gender_type_id,
            race_id: student.race_id,
            is_dual_language_learner: student.is_dual_language_learner,
            needs_spanish: student.needs_spanish,
            has_iep: student.has_iep,
            hispanic_ethnicity: student.hispanic_ethnicity,
            caregiver_name: student.caregiver_name,
            caregiver_email: student.caregiver_email,
            caregiver_mobile_number: student.caregiver_mobile_number,
            family_id: student.family_id,
            teacher_email: student.teacher_email,
            teacher_phone_number: student.mobile_phone_number,
            teacher_first_name: student.teacher_first_name,
            teacher_last_name: student.teacher_last_name,
        })
    }

    /**
     *
     * @param {StudentUpdate} student
     */
    static updateStudent(student) {
        const body = objKeysToSnakeCase(student)
        if (!!body.sis_id && !!body.sis_id.trim())
            body.sis_id = body.sis_id.trim()
        return this.put(`/students/${student.id}`, {
            id: student.id,
            username: student.username,
            first_name: student.first_name,
            last_name: student.last_name,
            clever_id: student.clever_id,
            sis_id: student.sis_id,
            grade_level_id: student.grade_level_id,
            school_id: student.school_id,
            reading_level_id: student.reading_level_id,
            gender_type_id: student.gender_type_id,
            race_id: student.race_id,
            is_dual_language_learner: student.is_dual_language_learner,
            needs_spanish: student.needs_spanish,
            has_iep: student.has_iep,
            hispanic_ethnicity: student.hispanic_ethnicity,
            caregiver_name: student.caregiver_name,
            caregiver_email: student.caregiver_email,
            caregiver_mobile_number: student.caregiver_mobile_number,
            family_id: student.family_id,
        })
    }

    static setStudentClever(studentId, cleverId) {
        return this.put(`/students/${studentId}/clever`, {
            clever_id: cleverId,
        })
    }

    static updateStudentScore(studentId, score) {
        const body = { score }
        return this.put(`/students/${studentId}/score`, body)
    }

    static getStudentGameHighScore(studentId) {
        return this.get(`/students/${studentId}/game_high_scores`)
    }

    static deleteStudent(studentId) {
        return this.delete(`/students/${studentId}`)
    }

    static deleteStudents(student_ids) {
        return this.post(`/students/delete_batch`, {
            student_ids,
        })
    }

    static unenrollStudents(student_ids) {
        return this.post(`/students/unenroll_batch`, {
            student_ids,
        })
    }

    static enrollStudent(studentId) {
        return this.put(`/students/${studentId}/enroll`)
    }

    static unenrollStudent(studentId) {
        return this.put(`/students/${studentId}/unenroll`)
    }

    static getPlatformMetrics() {
        return this.get('/platform_metrics', null, REPORTING_TIMEOUT)
    }

    static getSchoolMetrics(startDate, endDate) {
        let url = `/school_metrics?`
        if (startDate) url += `&start_date=${startDate.getTime()}`
        if (endDate) url += `&end_date=${endDate.getTime()}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getUsageReportForPlatform(
        startDate,
        endDate,
        sessionTypeCode,
        includeIncompleteSessions
    ) {
        let url = `/usage_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (!!sessionTypeCode) url += `&session_type_code=${sessionTypeCode}`
        if (includeIncompleteSessions)
            url += '&include_incomplete_sessions=true'

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getUsageReportForSchool(
        startDate,
        endDate,
        schoolId,
        tutorId,
        gradeLevelId,
        sessionTypeCode,
        includeIncompleteSessions
    ) {
        let url = `/schools/${schoolId}/usage_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (!!tutorId) url += `&tutor_id=${tutorId}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`
        if (!!sessionTypeCode) url += `&session_type_code=${sessionTypeCode}`
        if (includeIncompleteSessions)
            url += '&include_incomplete_sessions=true'

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getUsageReportForGuide(
        startDate,
        endDate,
        schoolId,
        tutorId,
        gradeLevelId,
        sessionTypeCode,
        includeIncompleteSessions
    ) {
        let url = `/tutors/${tutorId}/usage_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (!!schoolId) url += `&school_id=${schoolId}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`
        if (!!sessionTypeCode) url += `&session_type_code=${sessionTypeCode}`
        if (includeIncompleteSessions)
            url += '&include_incomplete_sessions=true'

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getUsageReportForStudent(
        startDate,
        endDate,
        studentId,
        sessionTypeCode,
        includeIncompleteSessions
    ) {
        let url = `/students/${studentId}/usage_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (!!sessionTypeCode) url += `&session_type_code=${sessionTypeCode}`
        if (includeIncompleteSessions)
            url += '&include_incomplete_sessions=true'

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getUsageRollupReportForPlatform(
        startDate,
        endDate,
        rollup,
        sessionTypeCode,
        includeIncompleteSessions
    ) {
        let url = `/usage_rollup_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (rollup !== undefined) url += `&rollup=${rollup}`
        if (!!sessionTypeCode) url += `&session_type_code=${sessionTypeCode}`
        if (includeIncompleteSessions)
            url += '&include_incomplete_sessions=true'

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getUsageRollupReportForSchool(
        startDate,
        endDate,
        rollup,
        schoolId,
        tutorId,
        gradeLevelId,
        sessionTypeCode,
        includeIncompleteSessions
    ) {
        let url = `/schools/${schoolId}/usage_rollup_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (rollup !== undefined) url += `&rollup=${rollup}`
        if (!!tutorId) url += `&tutor_id=${tutorId}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`
        if (!!sessionTypeCode) url += `&session_type_code=${sessionTypeCode}`
        if (includeIncompleteSessions)
            url += '&include_incomplete_sessions=true'

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getUsageRollupReportForGuide(
        startDate,
        endDate,
        rollup,
        schoolId,
        tutorId,
        gradeLevelId,
        sessionTypeCode,
        includeIncompleteSessions
    ) {
        let url = `/tutors/${tutorId}/usage_rollup_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (rollup !== undefined) url += `&rollup=${rollup}`
        if (!!schoolId) url += `&school_id=${schoolId}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`
        if (!!sessionTypeCode) url += `&session_type_code=${sessionTypeCode}`
        if (includeIncompleteSessions)
            url += '&include_incomplete_sessions=true'

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getUsageRollupReportForStudent(
        startDate,
        endDate,
        rollup,
        studentId,
        sessionTypeCode,
        includeIncompleteSessions
    ) {
        let url = `/students/${studentId}/usage_rollup_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (rollup !== undefined) url += `&rollup=${rollup}`
        if (!!sessionTypeCode) url += `&session_type_code=${sessionTypeCode}`
        if (includeIncompleteSessions)
            url += '&include_incomplete_sessions=true'

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStudentProgressReportForPlatform(startDate, endDate) {
        let url = `/student_progress_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStudentProgressReportForSchool(
        startDate,
        endDate,
        schoolId,
        tutorId,
        gradeLevelId
    ) {
        let url = `/schools/${schoolId}/student_progress_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (!!tutorId) url += `&tutor_id=${tutorId}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStudentProgressReportForGuide(
        startDate,
        endDate,
        schoolId,
        tutorId,
        gradeLevelId
    ) {
        let url = `/tutors/${tutorId}/student_progress_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (!!schoolId) url += `&school_id=${schoolId}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStudentProgressReportForStudent(startDate, endDate, studentId) {
        let url = `/students/${studentId}/student_progress_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStudentProgressRollupReportForPlatform(
        startDate,
        endDate,
        rollup
    ) {
        let url = `/student_progress_rollup_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (rollup !== undefined) url += `&rollup=${rollup}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStudentProgressRollupReportForSchool(
        startDate,
        endDate,
        rollup,
        schoolId,
        tutorId,
        gradeLevelId
    ) {
        let url = `/schools/${schoolId}/student_progress_rollup_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (rollup !== undefined) url += `&rollup=${rollup}`
        if (!!tutorId) url += `&tutor_id=${tutorId}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStudentProgressRollupReportForGuide(
        startDate,
        endDate,
        rollup,
        schoolId,
        tutorId,
        gradeLevelId
    ) {
        let url = `/tutors/${tutorId}/student_progress_rollup_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (rollup !== undefined) url += `&rollup=${rollup}`
        if (!!schoolId) url += `&school_id=${schoolId}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStudentProgressRollupReportForStudent(
        startDate,
        endDate,
        rollup,
        studentId
    ) {
        let url = `/students/${studentId}/student_progress_rollup_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (rollup !== undefined) url += `&rollup=${rollup}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getReadingLevelReportForSchool(schoolId, tutorId, gradeLevelId) {
        let url = `/schools/${schoolId}/reading_level_report?`
        if (!!tutorId) url += `&tutor_id=${tutorId}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getReadingLevelReportForGuide(schoolId, tutorId, gradeLevelId) {
        let url = `/tutors/${tutorId}/reading_level_report?`
        if (!!schoolId) url += `&school_id=${schoolId}`
        if (!!gradeLevelId) url += `&grade_level_id=${gradeLevelId}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStudentReadingLevelRollupReportForStudent(
        startDate,
        endDate,
        rollup,
        studentId
    ) {
        let url = `/students/${studentId}/student_reading_level_rollup_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (rollup !== undefined) url += `&rollup=${rollup}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStrugglingReportForSchool(schoolId) {
        let url = `/schools/${schoolId}/struggling_report?`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStrugglingReportForGuide(tutorId) {
        let url = `/tutors/${tutorId}/struggling_report?`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getSessionTypesReportForSchool(startDate, endDate, schoolId) {
        let url = `/schools/${schoolId}/session_types_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getSessionTypesReportForGuide(
        startDate,
        endDate,
        schoolId,
        tutorId
    ) {
        let url = `/tutors/${tutorId}/session_types_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`
        if (!!schoolId) url += `&school_id=${schoolId}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getMultisites(schoolId) {
        let url = `/multisites?`
        if (!!schoolId) url += `&schoolId=${schoolId}`

        return this.get(url)
    }

    static getUsageReportForMultisite(startDate, endDate, multisiteId) {
        let url = `/multisites/${multisiteId}/usage_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStrugglingReportForMultisite(multisiteId) {
        let url = `/multisites/${multisiteId}/struggling_report?`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getStudentProgressReportForMultisite(
        startDate,
        endDate,
        multisiteId
    ) {
        let url = `/multisites/${multisiteId}/student_progress_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getReadingLevelReportForMultisite(multisiteId) {
        let url = `/multisites/${multisiteId}/reading_level_report?`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getSessionTypesReportForMultisite(startDate, endDate, multisiteId) {
        let url = `/multisites/${multisiteId}/session_types_report?`
        if (!!startDate) url += `&start_date=${startDate.getTime()}`
        if (!!endDate) url += `&end_date=${endDate.getTime()}`

        return this.get(url, null, REPORTING_TIMEOUT)
    }

    static getPhonicsActivityStrugglingReportForSchool(schoolId) {
        return this.get(
            `/schools/${schoolId}/phonics_activity_struggling_report`
        )
    }

    static getPhonicsActivityReportForSchool(schoolId) {
        return this.get(`/schools/${schoolId}/phonics_activity_report`)
    }

    static searchBooks(opt = {}) {
        let url = '/books?'
        if (opt.limit) url += `&limit=${opt.limit}`
        if (opt.offset) url += `&offset=${opt.offset}`
        if (opt.includeInactive)
            url += `&include_inactive=${opt.includeInactive}`
        if (opt.excludeIds) url += `&exclude_ids=${opt.excludeIds}`
        if (opt.studentId) url += `&student_id=${opt.studentId}`
        if (opt.randomize) url += `&randomize=${opt.randomize}`
        if (opt.minReadingLevel)
            url += `&min_reading_level=${opt.minReadingLevel}`
        if (opt.maxReadingLevel)
            url += `&max_reading_level=${opt.maxReadingLevel}`
        if (opt.fictionTypeId) url += `&fiction_type_id=${opt.fictionTypeId}`
        if (opt.readingLevelId) url += `&reading_level_id=${opt.readingLevelId}`
        if (opt.orderBy) url += `&order_by=${opt.orderBy}`
        if (opt.orderDirection) url += `&order_direction=${opt.orderDirection}`
        if (opt.searchText) url += `&search_text=${opt.searchText}`

        return this.get(url)
    }

    static getStudentsNeedStandard(schoolId, standardId) {
        const url = `/schools/${schoolId}/standards/${standardId}/students_need`

        return this.get(url)
    }

    static createBook(
        fictionTypeId,
        type,
        title,
        description,
        pageCount,
        readingLevelId,
        bookFiles,
        pages
    ) {
        let data = new FormData()
        data.append('type', type)
        data.append('title', title)
        data.append('description', description)
        data.append('page_count', pageCount)
        data.append('fiction_type_id', fictionTypeId)
        data.append('reading_level_id', readingLevelId)
        bookFiles.forEach(f => {
            data.append('book_files', f)
        })
        data.append('pages', JSON.stringify(pages))

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post('books', data)
        return this.wrap(req)
    }

    static updateBook(
        id,
        fictionTypeId,
        type,
        title,
        description,
        pageCount,
        readingLevelId,
        bookFiles,
        pages
    ) {
        let data = new FormData()
        data.append('type', type)
        data.append('title', title)
        data.append('description', description)
        data.append('page_count', pageCount)
        data.append('fiction_type_id', fictionTypeId)
        data.append('reading_level_id', readingLevelId)
        bookFiles.forEach(f => {
            data.append('book_files', f)
        })
        data.append('pages', JSON.stringify(pages))

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).put(`books/${id}`, data)
        return this.wrap(req)
    }

    static activateBook(id) {
        return this.put(`/books/${id}/active`)
    }

    static deactivateBook(id) {
        return this.delete(`/books/${id}/active`)
    }

    static setStoppingPoints(id, stoppingPoints) {
        return this.put(`/books/${id}/stopping_points`, stoppingPoints)
    }

    static getBook(id) {
        return this.get(`/books/${id}`)
    }

    static deleteBook(id) {
        return this.delete(`/books/${id}`)
    }

    static createPassage(
        title,
        word_count,
        reading_level_id,
        content,
        questions
    ) {
        return this.post('/passages', {
            title,
            word_count,
            reading_level_id,
            content,
            questions,
        })
    }

    static updatePassage(
        id,
        title,
        word_count,
        reading_level_id,
        content,
        questions
    ) {
        return this.put(`/passages/${id}`, {
            title,
            word_count,
            reading_level_id,
            content,
            questions,
        })
    }

    static uploadPassageFile(id, file, audio_type) {
        let data = new FormData()
        if (audio_type) {
            data.append('audio_type', audio_type)
        }
        data.append('files', file)
        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post(`/passages/${id}/media`, data)
        return this.wrap(req)
    }

    static deletePassageFile(id, filename) {
        return this.delete(`/passages/${id}/media/${filename}`)
    }

    static activatePassage(id) {
        return this.put(`/passages/${id}/active`)
    }

    static deactivatePassage(id) {
        return this.delete(`/passages/${id}/active`)
    }

    static getPassage(id) {
        return this.get(`/passages/${id}`)
    }

    static getPassageForSessionStudent(sessionId, studentId) {
        return this.get(
            `/sessions/${sessionId}/students/${studentId}/passages/leveling`
        )
    }

    static getAllPassages(
        includeInactive,
        limit,
        offset,
        orderBy,
        orderDirection,
        readingLevelId,
        searchText
    ) {
        let url = `/passages?`

        if (!!includeInactive) url += `&include_inactive=${includeInactive}`
        if (!!limit) url += `&limit=${limit}`
        if (!!offset) url += `&offset=${offset}`
        if (!!orderBy) url += `&order_by=${orderBy}`
        if (!!orderDirection) url += `&order_direction=${orderDirection}`
        if (!!readingLevelId) url += `&reading_level_id=${readingLevelId}`
        if (!!searchText) url += `&search_text=${searchText}`

        return this.get(url)
    }

    static deletePassage(id) {
        return this.delete(`/passages/${id}`)
    }

    static authenticateStudent(username) {
        return this.post('/students/authenticate', { username })
    }

    static authenticateStudentBadge(badge_id) {
        return this.post('/students/authenticate', { badge_id })
    }

    static activateStudent(studentId) {
        return this.put(`/students/${studentId}/activate`)
    }

    static deactivateStudent(studentId) {
        return this.put(`/students/${studentId}/deactivate`)
    }

    static getStudent(
        studentId,
        includeSchool = false,
        includeMetrics = false
    ) {
        return this.get(
            `/students/${studentId}?includeSchool=${includeSchool}&includeMetrics=${includeMetrics}`
        )
    }

    static checkStudentUsername(username) {
        return this.post(`/students/check_username?username=${username}`)
    }

    static getAllStudents(opt = {}) {
        let url = '/students?'
        if (opt.limit) url += `&limit=${opt.limit}`
        if (opt.offset) url += `&offset=${opt.offset}`
        if (opt.searchText) url += `&search_text=${opt.searchText}`

        return this.get(url)
    }

    static createSchool(name, school_year_start, school_year_end, clever_id) {
        return this.post('/schools', {
            name,
            school_year_start,
            school_year_end,
            clever_id,
        })
    }

    static updateSchool(
        schoolId,
        name,
        school_year_start,
        school_year_end,
        clever_id
    ) {
        return this.put(`/schools/${schoolId}`, {
            name,
            school_year_start,
            school_year_end,
            clever_id,
        })
    }

    static activateSchool(id) {
        return this.put(`/schools/${id}/active`)
    }

    static deactivateSchool(id) {
        return this.delete(`/schools/${id}/active`)
    }

    static getAllSchools() {
        return this.get(`/schools`)
    }

    static getSchool(schoolId) {
        return this.get(`/schools/${schoolId}`)
    }

    static getSchoolFeatures(schoolId) {
        return this.get(`/schools/${schoolId}/features`)
    }

    static getAllFeatures() {
        return this.get(`/features`)
    }

    static addFeatureAccessToSchool(schoolId, featureId) {
        return this.put(`/schools/${schoolId}/features/${featureId}`)
    }

    static removeFeatureAccessFromSchool(schoolId, featureId) {
        return this.delete(`/schools/${schoolId}/features/${featureId}`)
    }

    static getTeachersForSchool(schoolId) {
        return this.get(`/schools/${schoolId}/teachers`)
    }

    static getStudentsForSchool(
        schoolId,
        includeMetrics = false,
        offset = null,
        limit = null,
        orderBy = null,
        orderDirection = null,
        searchText = null,
        gradeLevelId = null,
        readingLevelId = null,
        isEnrolled = null
    ) {
        let url = `/schools/${schoolId}/students?`

        if (!!includeMetrics) url += `&includeMetrics=${includeMetrics}`
        if (!!offset) url += `&offset=${offset}`
        if (!!limit) url += `&limit=${limit}`
        if (!!orderBy) url += `&orderBy=${orderBy}`
        if (!!orderDirection) url += `&orderDirection=${orderDirection}`
        if (!!gradeLevelId) url += `&gradeLevelId=${gradeLevelId}`
        if (!!readingLevelId) url += `&readingLevelId=${readingLevelId}`
        if (!!searchText) url += `&searchText=${searchText}`
        if (isEnrolled === false || isEnrolled === true)
            url += `&isEnrolled=${isEnrolled}`

        return this.get(url)
    }

    static getStudentCountForSchool({
        schoolId,
        gradeLevelId,
        readingLevelId,
        searchText,
        isEnrolled,
    }) {
        let url = `/schools/${schoolId}/students/count?`

        if (!!gradeLevelId) url += `&gradeLevelId=${gradeLevelId}`
        if (!!readingLevelId) url += `&readingLevelId=${readingLevelId}`
        if (!!searchText) url += `&searchText=${searchText}`
        if (!!isEnrolled) url += `&isEnrolled=${isEnrolled}`

        return this.get(url)
    }

    static enrollAllStudentsForSchool(schoolId) {
        return this.put(`/schools/${schoolId}/students/enroll`)
    }

    static unenrollAllStudentsForSchool(schoolId) {
        return this.put(`/schools/${schoolId}/students/unenroll`)
    }

    static getSchoolGuides(
        schoolId,
        includePending = false,
        offset,
        limit,
        orderBy,
        orderDirection,
        searchText
    ) {
        let url = `/schools/${schoolId}/tutors-schools?includePending=${includePending}`

        if (!!offset) url += `&offset=${offset}`
        if (!!limit) url += `&limit=${limit}`
        if (!!orderBy) url += `&orderBy=${orderBy}`
        if (!!orderDirection) url += `&orderDirection=${orderDirection}`
        if (!!searchText) url += `&searchText=${searchText}`

        return this.get(url)
    }

    static getSessionsForSchool(schoolId, offset, count, shallow = true) {
        return this.get(
            `/schools/${schoolId}/sessions?offset=${offset}&count=${count}&shallow=${shallow}`
        )
    }

    static getSessionsForTutor(tutorId, offset, count, schoolId) {
        let url = `/tutors/${tutorId}/sessions?offset=${offset}&count=${count}`

        if (Boolean(schoolId)) url += `&schoolId=${schoolId}`

        return this.get(url)
    }

    static getSessionsForStudent(studentId, offset, count) {
        return this.get(
            `/students/${studentId}/sessions?offset=${offset}&count=${count}`
        )
    }

    static getTutorFromSessionId(sessionId) {
        return this.get(`/sessions/${sessionId}/tutor`)
    }

    static deleteSchool(id) {
        return this.delete(`/schools/${id}`)
    }

    static getMultisite(id) {
        return this.get(`/multisites/${id}`)
    }

    static getLatestCleverSyncResultsForMultisite(cleverDistrictToken) {
        return this.get(
            `/clever/sync/latest?clever_district_token=${cleverDistrictToken}`
        )
    }

    static getLatestCleverSyncValidateResultsForMultisite(cleverDistrictToken) {
        return this.get(
            `/clever/validate/latest?clever_district_token=${cleverDistrictToken}`
        )
    }

    static cleverSyncStart(cleverDistrictToken) {
        return this.post(`/clever/sync`, {
            cleverDistrictToken,
        })
    }

    static cleverValidateSync(cleverDistrictToken) {
        return this.post(`/clever/validate`, {
            cleverDistrictToken,
        })
    }

    static cleverValidateSyncCheckProgress(jobId) {
        return this.get(`/clever/validate/${jobId}`)
    }

    static getAllMultisites() {
        return this.get('/multisites')
    }

    static createMultisite(name) {
        return this.post('/multisites', {
            name,
        })
    }

    static updateMultisite(
        id,
        { name, cleverDistrictToken, autoCleverSyncEnabled }
    ) {
        return this.put(`/multisites/${id}`, {
            name,
            cleverDistrictToken,
            autoCleverSyncEnabled,
        })
    }

    static addSchoolToMultisite(id, schoolId) {
        return this.put(`/multisites/${id}/schools/${schoolId}`)
    }

    static removeSchoolFromMultisite(id, schoolId) {
        return this.delete(`/multisites/${id}/schools/${schoolId}`)
    }

    static addTutorToMultisite(id, tutorId) {
        return this.put(`/multisites/${id}/tutors/${tutorId}`)
    }

    static removeTutorFromMultisite(id, tutorId) {
        return this.delete(`/multisites/${id}/tutors/${tutorId}`)
    }

    static deleteMultisite(id) {
        return this.delete(`/multisites/${id}`)
    }

    static getAllActivityTypes() {
        return this.get('/activity_types')
    }

    static getAllMediaTypes() {
        return this.get('/media_types')
    }

    static getAllFictionTypes() {
        return this.get('/fiction_types')
    }

    static getAllReadingLevels() {
        return this.get('/reading_levels')
    }

    static getAllGradeLevels() {
        return this.get('/grade_levels')
    }

    static getAllPublishers() {
        return this.get('/publishers')
    }

    static addPublisherAccessToSchool(schoolId, publisherId) {
        return this.put(`/schools/${schoolId}/publishers/${publisherId}`)
    }

    static removePublisherAccessFromSchool(schoolId, publisherId) {
        return this.delete(`/schools/${schoolId}/publishers/${publisherId}`)
    }

    static createStandard(
        grade_level_id,
        standard_type_id,
        name,
        guide_summary,
        student_summary,
        guide_description,
        student_description,
        spanish_guide_summary,
        spanish_student_summary,
        spanish_guide_description,
        spanish_student_description,
        is_optional,
        questions
    ) {
        return this.post('/standards', {
            name,
            guide_summary,
            student_summary,
            guide_description,
            student_description,
            spanish_guide_summary,
            spanish_student_summary,
            spanish_guide_description,
            spanish_student_description,
            grade_level_id,
            standard_type_id,
            is_optional,
            questions,
        })
    }

    static updateStandard(
        id,
        grade_level_id,
        standard_type_id,
        name,
        guide_summary,
        student_summary,
        guide_description,
        student_description,
        spanish_guide_summary,
        spanish_student_summary,
        spanish_guide_description,
        spanish_student_description,
        is_optional,
        questions
    ) {
        return this.put(`/standards/${id}`, {
            name,
            guide_summary,
            student_summary,
            guide_description,
            student_description,
            spanish_guide_summary,
            spanish_student_summary,
            spanish_guide_description,
            spanish_student_description,
            grade_level_id,
            standard_type_id,
            is_optional,
            questions,
        })
    }

    static getStandard(id) {
        return this.get(`/standards/${id}`)
    }

    static deleteStandard(id) {
        return this.delete(`/standards/${id}`)
    }

    static getAllStandardTypes() {
        return this.get('/standard_types')
    }

    static getAllStandards(
        lesson_type_id,
        reading_level_id,
        standard_type_id,
        limit,
        offset,
        order_by,
        order_direction,
        search_text
    ) {
        let url = '/standards?'

        if (!!lesson_type_id) url += `&lesson_type_id=${lesson_type_id}`
        if (!!reading_level_id) url += `&reading_level_id=${reading_level_id}`
        if (!!standard_type_id) url += `&standard_type_id=${standard_type_id}`
        if (!!limit) url += `&limit=${limit}`
        if (!!offset) url += `&offset=${offset}`
        if (!!order_by) url += `&order_by=${order_by}`
        if (!!order_direction) url += `&order_direction=${order_direction}`
        if (!!search_text) url += `&search_text=${search_text}`

        return this.get(url)
    }

    static swapStandardSequences(standard_1_id, standard_2_id) {
        return this.post('/standards/swap', {
            standard_1_id,
            standard_2_id,
        })
    }

    static createComprehensionLesson(
        book_id,
        publisher_id,
        publisher_sequence,
        standard_id,
        name,
        description,
        has_spanish,
        reading_level_id,
        vocabulary,
        vocabulary_questions,
        vocabulary_game_questions,
        comprehension_questions,
        comprehension_game_questions
    ) {
        return this.post('/lessons/comprehension', {
            book_id,
            publisher_id,
            publisher_sequence,
            standard_id,
            name,
            description,
            has_spanish,
            reading_level_id,
            vocabulary,
            vocabulary_questions,
            vocabulary_game_questions,
            comprehension_questions,
            comprehension_game_questions,
        })
    }

    static createFluencyLesson(
        publisher_id,
        publisher_sequence,
        standard_id,
        name,
        description,
        fluency_modeling_passage_id,
        fluency_game_passage_id,
        reading_level_id
    ) {
        return this.post('/lessons/fluency', {
            publisher_id,
            publisher_sequence,
            standard_id,
            name,
            description,
            fluency_modeling_passage_id,
            fluency_game_passage_id,
            has_spanish: false,
            reading_level_id,
        })
    }

    static createKOneLesson(
        publisher_id,
        publisher_sequence,
        standard_id,
        name,
        description,
        has_spanish,
        activities,
        reading_level_id
    ) {
        return this.post('/lessons/k_one', {
            publisher_id,
            publisher_sequence,
            standard_id,
            name,
            description,
            has_spanish,
            activities,
            reading_level_id,
        })
    }

    static updateComprehensionLesson(
        lessonId,
        book_id,
        publisher_id,
        publisher_sequence,
        standard_id,
        name,
        description,
        has_spanish,
        vocabulary,
        vocabulary_questions,
        vocabulary_game_questions,
        comprehension_questions,
        comprehension_game_questions,
        reading_level_id
    ) {
        return this.put(`/lessons/comprehension/${lessonId}`, {
            book_id,
            publisher_id,
            publisher_sequence,
            standard_id,
            name,
            description,
            has_spanish,
            vocabulary,
            vocabulary_questions,
            vocabulary_game_questions,
            comprehension_questions,
            comprehension_game_questions,
            reading_level_id,
        })
    }

    static updateFluencyLesson(
        lessonId,
        publisher_id,
        publisher_sequence,
        standard_id,
        name,
        description,
        fluency_modeling_passage_id,
        fluency_game_passage_id,
        reading_level_id
    ) {
        return this.put(`/lessons/fluency/${lessonId}`, {
            publisher_id,
            publisher_sequence,
            standard_id,
            name,
            description,
            fluency_modeling_passage_id,
            fluency_game_passage_id,
            has_spanish: false,
            reading_level_id,
        })
    }

    static updateKOneLesson(
        lessonId,
        publisher_id,
        publisher_sequence,
        standard_id,
        name,
        description,
        has_spanish,
        activities,
        reading_level_id
    ) {
        return this.put(`/lessons/k_one/${lessonId}`, {
            publisher_id,
            publisher_sequence,
            standard_id,
            name,
            description,
            has_spanish,
            activities,
            reading_level_id,
        })
    }

    static deleteLesson(lessonId) {
        return this.delete(`/lessons/${lessonId}`)
    }

    static activateLesson(lessonId) {
        return this.put(`/lessons/${lessonId}/active`)
    }

    static deactivateLesson(lessonId) {
        return this.delete(`/lessons/${lessonId}/active`)
    }

    static duplicateLesson(lessonId) {
        return this.post(`/lessons/${lessonId}/duplicate`)
    }

    static getAllLessons(opt = {}) {
        if (cancelGetAllLessons) cancelGetAllLessons()

        let url = '/lessons?'
        if (opt.limit) url += `&limit=${opt.limit}`
        if (opt.offset) url += `&offset=${opt.offset}`
        if (opt.lessonTypeId) url += `&lessonTypeId=${opt.lessonTypeId}`
        if (opt.publisherId) url += `&publisherId=${opt.publisherId}`
        if (opt.fictionTypeId) url += `&fictionTypeId=${opt.fictionTypeId}`
        if (opt.readingLevelId) url += `&readingLevelId=${opt.readingLevelId}`
        if (opt.orderBy) url += `&orderBy=${opt.orderBy}`
        if (opt.orderDirection) url += `&orderDirection=${opt.orderDirection}`
        if (opt.searchText) url += `&searchText=${opt.searchText}`

        return this.get(
            url,
            null,
            DEFAULT_TIMEOUT,
            cancel => (cancelGetAllLessons = cancel)
        )
    }

    static getAvailableLessons(
        student_ids,
        reading_level_sequence,
        lesson_type_id,
        standard_id,
        school_id,
        tutor_id,
        spanish_only = false,
        publisher_id
    ) {
        return this.post('/lessons/available', {
            reading_level_sequence,
            lesson_type_id,
            student_ids,
            standard_id,
            school_id,
            tutor_id,
            spanish_only,
            publisher_id,
        })
    }

    static getRecommendedLessons(
        student_ids,
        session_type_code,
        count,
        publisher_id
    ) {
        return this.post('/lessons/recommended', {
            student_ids,
            session_type_code,
            count,
            publisher_id,
        })
    }

    static getRecommendedLessonsForIndependentSession(count) {
        return this.post('/lessons/recommended_for_independent_session', {
            count,
        })
    }

    static getGroupRecommendations(student_ids, publisher_id) {
        return this.post('/session_groups/recommendations', {
            student_ids,
            publisher_id,
        })
    }

    static getSessionGroups(count) {
        let url = `/session_groups?`
        if (!!count) url += `&count=${count}`

        return this.get(url)
    }

    static getStudentGroups() {
        return this.get(`/student_groups`)
    }

    static getStudentGroup(id) {
        return this.get(`/student_groups/${id}`)
    }

    static createStudentGroup(student_ids, name) {
        const body = {
            student_ids,
            name,
        }
        return this.post('/student_groups', body)
    }

    static updateStudentGroup(id, student_ids, name) {
        const body = {
            student_ids,
            name,
        }
        return this.put(`/student_groups/${id}`, body)
    }

    static deleteStudentGroup(id) {
        return this.delete(`/student_groups/${id}`)
    }

    static completeSessionGroup(sessionGroupId) {
        return this.put(`/session_groups/${sessionGroupId}/complete`)
    }

    static getLesson(lessonId) {
        return this.get(`/lessons/${lessonId}`)
    }

    static uploadMediaFile(file, mediaTypeId, isSpanish, name) {
        let data = new FormData()
        data.append('media_type_id', mediaTypeId)
        data.append('is_spanish', isSpanish)
        if (name) data.append('name', name)
        data.append('files', file)

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post(`/media`, data)

        return this.wrap(req)
    }

    static deleteMediaFile(mediaId) {
        return this.delete(`/media/${mediaId}`)
    }

    static deleteVocabFile(lessonId, vocabId, filename) {
        return this.delete(
            `/lessons/${lessonId}/vocabulary/${vocabId}/media/${filename}`
        )
    }

    static uploadVocabFile(lessonId, vocabId, file, mediaTypeId, isSpanish) {
        let data = new FormData()
        data.append('media_type_id', mediaTypeId)
        data.append('is_spanish', isSpanish)
        data.append('files', file)

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post(`/lessons/${lessonId}/vocabulary/${vocabId}/media`, data)
        return this.wrap(req)
    }

    static addVocabularyMedia(lessonId, vocabularyId, mediaId) {
        return this.post(
            `/lessons/${lessonId}/vocabulary/${vocabularyId}/media/${mediaId}`
        )
    }

    static uploadActivityMedia(
        lessonId,
        activityId,
        mediaTypeId,
        files,
        isSpanish
    ) {
        let data = new FormData()
        data.append('media_type_id', mediaTypeId)
        data.append('is_spanish', isSpanish)
        data.append('files', files)

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post(`/lessons/${lessonId}/activities/${activityId}/media`, data)

        return this.wrap(req)
    }

    static uploadActivityBlockMedia(
        lessonId,
        activityId,
        activityBlockId,
        mediaTypeId,
        files,
        isSpanish
    ) {
        let data = new FormData()
        data.append('media_type_id', mediaTypeId)
        data.append('is_spanish', isSpanish)
        data.append('files', files)

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post(
            `/lessons/${lessonId}/activities/${activityId}/blocks/${activityBlockId}/media`,
            data
        )

        return this.wrap(req)
    }

    static addActivityMedia(lessonId, activityId, mediaId) {
        return this.post(
            `/lessons/${lessonId}/activities/${activityId}/media/${mediaId}`
        )
    }

    static addActivityBlockMedia(lessonId, activityId, blockId, mediaId) {
        return this.post(
            `/lessons/${lessonId}/activities/${activityId}/blocks/${blockId}/media/${mediaId}`
        )
    }

    static uploadQuestionMedia(
        lessonId,
        activityId,
        questionId,
        mediaTypeId,
        files,
        isSpanish
    ) {
        let data = new FormData()
        data.append('media_type_id', mediaTypeId)
        data.append('is_spanish', isSpanish)
        data.append('files', files)

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post(
            `/lessons/${lessonId}/activities/${activityId}/questions/${questionId}/media`,
            data
        )

        return this.wrap(req)
    }

    static addQuestionMedia(lessonId, activityId, questionId, mediaId) {
        return this.post(
            `/lessons/${lessonId}/activities/${activityId}/questions/${questionId}/media/${mediaId}`
        )
    }

    static uploadQuestionOptionMedia(
        lessonId,
        activityId,
        questionId,
        quesitonOptionId,
        media_type_id,
        files
    ) {
        let data = new FormData()
        data.append('media_type_id', media_type_id)
        data.append('files', files)

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post(
            `/lessons/${lessonId}/activities/${activityId}/questions/${questionId}/options/${quesitonOptionId}/media`,
            data
        )

        return this.wrap(req)
    }

    static addQuestionOptionMedia(
        lessonId,
        activityId,
        questionId,
        optionId,
        mediaId
    ) {
        return this.post(
            `/lessons/${lessonId}/activities/${activityId}/questions/${questionId}/options/${optionId}/media/${mediaId}`
        )
    }

    static uploadStandardMedia(
        standardId,
        files,
        mediaTypeId,
        isSpanish,
        name
    ) {
        let data = new FormData()
        data.append('media_type_id', mediaTypeId)
        data.append('is_spanish', isSpanish)
        if (name) data.append('name', name)
        data.append('files', files)

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post(`/standards/${standardId}/media`, data)

        return this.wrap(req)
    }

    static addStandardMedia(standardId, mediaId) {
        return this.post(`/standards/${standardId}/media/${mediaId}`)
    }

    static deleteStandardMedia(standardId, mediaId) {
        return this.delete(`/standards/${standardId}/media/${mediaId}`)
    }

    static deleteActivityMedia(lessonId, activityId, mediaId) {
        return this.delete(
            `/lessons/${lessonId}/activities/${activityId}/media/${mediaId}`
        )
    }

    static uploadStandardQuestionMedia(
        standardId,
        questionId,
        files,
        mediaTypeId,
        isSpanish
    ) {
        let data = new FormData()
        data.append('media_type_id', mediaTypeId)
        data.append('is_spanish', isSpanish)
        data.append('files', files)

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post(`/standards/${standardId}/questions/${questionId}/media`, data)

        return this.wrap(req)
    }

    static addStandardQuestionMedia(standardId, questionId, mediaId) {
        return this.post(
            `/standards/${standardId}/questions/${questionId}/media/${mediaId}`
        )
    }

    static deleteStandardQuestionMedia(standardId, questionId, mediaId) {
        return this.delete(
            `/standards/${standardId}/questions/${questionId}/media/${mediaId}`
        )
    }

    static deleteActivityBlockMedia(
        lessonId,
        activityId,
        activityBlockId,
        mediaId
    ) {
        return this.delete(
            `/lessons/${lessonId}/activities/${activityId}/blocks/${activityBlockId}/media/${mediaId}`
        )
    }

    static deleteQuestionMedia(lessonId, activityId, questionId, mediaId) {
        return this.delete(
            `/lessons/${lessonId}/activities/${activityId}/questions/${questionId}/media/${mediaId}`
        )
    }

    static deleteQuestionOptionMedia(
        lessonId,
        activityId,
        questionId,
        questionOptionId,
        mediaId
    ) {
        return this.delete(
            `/lessons/${lessonId}/activities/${activityId}/questions/${questionId}/options/${questionOptionId}/media/${mediaId}`
        )
    }

    static uploadBookPageMedia(bookId, pageId, mediaTypeId, files) {
        let data = new FormData()
        data.append('media_type_id', mediaTypeId)
        data.append('files', files)

        const req = this.getInstance(
            DEFAULT_TIMEOUT,
            'multipart/form-data'
        ).post(`/books/${bookId}/pages/${pageId}/media`, data)

        return this.wrap(req)
    }

    static addBookPageMedia(bookId, pageId, mediaId) {
        return this.post(`/books/${bookId}/pages/${pageId}/media/${mediaId}`)
    }

    static deleteBookPageMedia(bookId, pageId, mediaId) {
        return this.delete(`/books/${bookId}/pages/${pageId}/media/${mediaId}`)
    }

    static startSessionGroup(sessions) {
        return this.post('/session_groups', { sessions })
    }

    static getSessionGroup(sessionGroupId) {
        return this.get(`/session_groups/${sessionGroupId}`)
    }

    static getSessionsBySessionGroup(sessionGroupId) {
        return this.get(`/session_groups/${sessionGroupId}/sessions`)
    }

    static authSessionGroupConnection(sessionGroupId) {
        return this.get(`/session_groups/${sessionGroupId}/auth`)
    }

    static startSession(
        session_type_id,
        student_ids,
        lesson_id,
        is_spanish = false,
        state,
        reading_level_id_override = null
    ) {
        const body = {
            session_type_id,
            student_ids,
            lesson_id,
            is_spanish,
            state,
            reading_level_id_override,
        }
        return this.post('/sessions', body)
    }

    static getSession(sessionId) {
        return this.get(`/sessions/${sessionId}`)
    }

    static deleteSession(sessionId) {
        return this.delete(`/sessions/${sessionId}`)
    }

    static getSessionStudents(sessionId, joined) {
        return this.get(
            `/sessions/${sessionId}/session_students?joined=${joined}`
        )
    }

    static updateSessionLastSeen(sessionId) {
        return this.post(`/sessions/${sessionId}/last_seen`)
    }

    static updateSessionStudentLastCheckpoint(
        sessionId,
        studentId,
        checkpointId,
        sequence
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/last_checkpoint`,
            {
                checkpointId,
                sequence,
            }
        )
    }

    /**
     * Join session
     * @param {*} sessionId
     * @param {*} studentId
     */
    static joinSession(sessionId, studentId) {
        return this.post(`/sessions/${sessionId}/students/${studentId}/join`)
    }

    static getStudentSessions(studentId) {
        return this.get(`/students/${studentId}/sessions`)
    }

    static setLevelingScoreAccuracy(
        sessionId,
        studentId,
        duration_seconds,
        errors
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/leveling_score/accuracy`,
            { duration_seconds, errors }
        )
    }

    static completeLevelingScore(sessionId, studentId, is_approved) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/leveling_score/complete`,
            { is_approved }
        )
    }

    static getLevelingScore(sessionId, studentId) {
        return this.get(
            `/sessions/${sessionId}/students/${studentId}/leveling_score`
        )
    }

    static getFluencyReadingPassage(sessionId, studentId) {
        return this.get(
            `/sessions/${sessionId}/students/${studentId}/fluency_read/passage`
        )
    }

    static setFluencyReadingScore(
        sessionId,
        studentId,
        duration_seconds,
        errors
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/fluency_read/score`,
            { duration_seconds, errors }
        )
    }

    static getFluencyReadingScore(sessionId, studentId) {
        return this.get(
            `/sessions/${sessionId}/students/${studentId}/fluency_read/score`
        )
    }

    static submitVocabularyQuestionResponse(
        sessionId,
        studentId,
        questionId,
        question_option_id
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/vocabulary/questions/${questionId}/responses`,
            { question_option_id }
        )
    }

    static submitVocabularyGameQuestionScore(
        sessionId,
        studentId,
        questionId,
        score
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/vocabulary_game/questions/${questionId}/score`,
            { score }
        )
    }

    static submitComprehensionQuestionResponse(
        sessionId,
        studentId,
        questionId,
        question_option_id,
        opened_book = false
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/comprehension/questions/${questionId}/responses`,
            { question_option_id, opened_book }
        )
    }

    static submitComprehensionGameQuestionResponse(
        sessionId,
        studentId,
        questionId,
        question_option_id,
        answer_sequence
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/comprehension_game/questions/${questionId}/responses`,
            { question_option_id, answer_sequence }
        )
    }

    static submitComprehensionGameQuestionScore(
        sessionId,
        studentId,
        questionId,
        score
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/comprehension_game/questions/${questionId}/score`,
            { score }
        )
    }

    static submitActivityQuestionResponse(
        sessionId,
        studentId,
        activityId,
        question_id,
        question_option_id,
        answer_sequence
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/activities/${activityId}/responses`,
            { question_id, question_option_id, answer_sequence }
        )
    }

    static submitPassageQuestionResponse(
        sessionId,
        studentId,
        passageId,
        question_option_id
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/passages/${passageId}/responses`,
            { question_option_id }
        )
    }

    static getPassageQuestionResponses(sessionId, studentId, passageId) {
        return this.get(
            `/sessions/${sessionId}/students/${studentId}/passages/${passageId}/responses`
        )
    }

    static submitFluencyGameQuestionScore(
        sessionId,
        studentId,
        fluencyTypeId,
        score
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/fluency_game/fluency_types/${fluencyTypeId}/score`,
            { score }
        )
    }

    static submitStandardQuestionResponse(
        sessionId,
        studentId,
        questionId,
        question_option_id
    ) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/standards/questions/${questionId}/responses`,
            { question_option_id }
        )
    }

    static submitStrugglingActivity(sessionId, studentId, activityId) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/activities/${activityId}/struggling`
        )
    }

    static deleteStrugglingActivity(sessionId, studentId, activityId) {
        return this.delete(
            `/sessions/${sessionId}/students/${studentId}/activities/${activityId}/struggling`
        )
    }

    static getRecentVocabularyGameQuestions(studentId) {
        let url = `/students/${studentId}/recent_vocabulary_game_questions`

        return this.get(url)
    }

    static getSessionReadingLevelChanges(sessionId, studentIds) {
        return Promise.all(
            studentIds.map(id =>
                this.get(
                    `sessions/${sessionId}/students/${id}/reading_level_change`,
                    data => ({ ...data, id })
                )
            )
        )
    }

    static completeSession(sessionId) {
        return this.put(`/sessions/${sessionId}/complete`)
    }

    static removeStudentFromSession(sessionId, studentId) {
        return this.put(`/sessions/${sessionId}/students/${studentId}/remove`)
    }

    static submitNotes(sessionId, studentId, notes) {
        return this.put(`/sessions/${sessionId}/students/${studentId}/notes`, {
            notes,
        })
    }

    static submitBookRating(sessionId, studentId, rating) {
        return this.put(
            `/sessions/${sessionId}/students/${studentId}/book_rating`,
            { rating }
        )
    }

    static submitSessionStudentFeedback(
        sessionId,
        studentId,
        feedbackTypeId,
        rating,
        yes
    ) {
        const params = {
            feedback_type_id: feedbackTypeId,
        }

        if (typeof rating === 'number') {
            params.rating = rating
        } else {
            params.yes = yes ? 'true' : 'false'
        }

        return this.put(
            `/sessions/${sessionId}/students/${studentId}/feedback`,
            params
        )
    }

    static getFeedbackTypes() {
        return this.get('/feedback_types')
    }

    static getSessionTypes() {
        return this.get('/session_types')
    }

    static getLessonTypes() {
        return this.get('/lesson_types')
    }

    static getFluencyTypes() {
        return this.get('/fluency_types')
    }

    static getGenderTypes() {
        return this.get('/gender_types')
    }

    static getRaces() {
        return this.get('/races')
    }

    static getTutorTypes() {
        return this.get('/tutor_types')
    }

    static getNetworkTestBooks() {
        return this.get('/network_test/books')
    }

    static authFirebaseNetworkTest(authKey) {
        return this.post(`/network_test/firebase/auth/${authKey}`)
    }

    static startFirebaseNetworkTest() {
        return this.post('/network_test/firebase/start')
    }

    static updateFirebaseNetworkTest(firebaseId, messageId) {
        return this.put(`/network_test/firebase/update/${firebaseId}`, {
            messageId: messageId,
        })
    }

    static cleanupFirebaseNetworkTest(firebaseId) {
        return this.delete(`/network_test/firebase/cleanup/${firebaseId}`)
    }

    static get(url, mapper = null, timeout = DEFAULT_TIMEOUT, getCancel) {
        let req = this.getInstance(timeout).get(url, {
            cancelToken: getCancel && new axios.CancelToken(getCancel),
        })
        return this.wrap(req, mapper)
    }

    static post(url, data = {}, mapper = null, timeout = DEFAULT_TIMEOUT) {
        let req = this.getInstance(timeout).post(url, data)
        return this.wrap(req, mapper)
    }

    static put(url, data = {}, mapper = null, timeout = DEFAULT_TIMEOUT) {
        let req = this.getInstance(timeout).put(url, data)
        return this.wrap(req, mapper)
    }

    static delete(url, mapper = null, timeout = DEFAULT_TIMEOUT) {
        let req = this.getInstance(timeout).delete(url)
        return this.wrap(req, mapper)
    }

    static wrap(request, mapper) {
        return new Promise((resolve, reject) => {
            request.then(
                res => {
                    let result = res.data
                    if (mapper) {
                        result = mapper(res.data)
                    }
                    resolve(result)
                },
                err => {
                    if (
                        err.response &&
                        (err.response.status === 400 ||
                            err.response.status === 401) &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        let newError = Error(err.response.data.message)
                        newError.status = err.response.status
                        newError.validationErrors =
                            err.response.data.validation_errors
                        reject(newError)
                    } else {
                        reject(err)
                    }
                }
            )
        })
    }

    static getInstance(
        timeout = DEFAULT_TIMEOUT,
        contentType = 'application/json'
    ) {
        let headers = {
            'Content-Type': contentType,
        }

        if (Auth.isLoggedIn()) {
            headers['Authorization'] = Auth.getAuthToken()
        }

        return axios.create({
            baseURL: BASE_API_URL,
            timeout: timeout,
            headers: headers,
        })
    }

    static getAllPrograms() {
        return this.get('/v1/programs')
    }

    static getProgram(id) {
        return this.get(`/v1/programs/${id}`)
    }

    static createProgram(name, description) {
        return this.post('/v1/programs', { name, description })
    }

    static updateProgram(id, name, description) {
        return this.put(`/v1/programs/${id}`, { name, description })
    }

    static removeProgram(id) {
        return this.delete(`/v1/programs/${id}`)
    }

    static getProgramTutors(
        id,
        { onlyWithProgram, limit, offset, searchText }
    ) {
        let url = `/v1/programs/${id}/tutors?&only_with_program=${onlyWithProgram}&limit=${limit}&offset=${offset}&search_text=${searchText}`
        return this.get(url, null, DEFAULT_TIMEOUT)
    }

    static updateTutorProgram(id, tutorId, isAssigned) {
        return this.put(`/v1/programs/${id}/tutors/${tutorId}`, { isAssigned })
    }

    static getProgramStudents(
        id,
        { onlyWithProgram, limit, offset, searchText }
    ) {
        let url = `/v1/programs/${id}/students?&only_with_program=${onlyWithProgram}&limit=${limit}&offset=${offset}&search_text=${searchText}`
        return this.get(url, null, DEFAULT_TIMEOUT)
    }

    static updateStudentProgram(id, studentId, isAssigned) {
        return this.put(`/v1/programs/${id}/students/${studentId}`, {
            isAssigned,
        })
    }

    static getProgramStudentsCount(id, { onlyWithProgram, searchText }) {
        let url = `/v1/programs/${id}/students/count?&only_with_program=${onlyWithProgram}&search_text=${searchText}`
        return this.get(url, null, DEFAULT_TIMEOUT)
    }

    static updateTimeblockProgram(id, timeblockId) {
        return this.put(`/v1/programs/${id}/timeblocks/${timeblockId}`)
    }

    static getProgramTimeblocks(id) {
        let url = `/v1/programs/${id}/timeblocks`
        return this.get(url)
    }
    static getProgramTutorsCount(id, { onlyWithProgram, searchText }) {
        let url = `/v1/programs/${id}/tutors/count?&only_with_program=${onlyWithProgram}&search_text=${searchText}`
        return this.get(url)
    }

    static hasStudentAccess({ tutorId }) {
        let url = `/tutors/${tutorId}/has_student_access`
        return this.get(url)
    }

    static getAllTimeblocks(limit, offset) {
        let url = `/timeblocks/?limit=${limit}&offset=${offset}`
        return this.get(url)
    }

    static getTimeblocksCount() {
        let url = `/timeblocks/count`
        return this.get(url)
    }

    static getTimeblock(id) {
        return this.get(`/timeblocks/${id}`)
    }

    static createTimeblock(name) {
        return this.post('/timeblocks', { name })
    }

    static updateTimeblock(id, name) {
        return this.put(`/timeblocks/${id}`, { name })
    }

    static removeTimeblock(id) {
        return this.delete(`/timeblocks/${id}`)
    }
    static getMetricsProgram(id) {
        let url = `/v1/programs/${id}/metrics`
        return this.get(url)
    }
    static removeTutorProgram(id) {
        return this.delete(`/tutors/${id}/remove_program`)
    }
    static getTutoringGroups(limit, offset) {
        let url = `/tutoring_groups/?limit=${limit}&offset=${offset}`
        return this.get(url)
    }
    static getTutoringGroupsCount() {
        let url = `/tutoring_groups/count`
        return this.get(url)
    }
    static getTutoringGroup(id) {
        let url = `/tutoring_groups/${id}`
        return this.get(url)
    }
    static getTutoringGroupStudents(id) {
        let url = `/tutoring_groups/${id}/students`
        return this.get(url)
    }
    static deleteTutoringGroup(id) {
        let url = `/tutoring_groups/${id}`
        return this.delete(url)
    }
    static importStudentToProgram(student_id, program_id) {
        return this.put(`/v1/programs/${program_id}/import/students/`, {
            student_id,
        })
    }
    static importTutorToProgram(tutor_id, program_id) {
        return this.put(`/v1/programs/${program_id}/import/tutors/`, {
            tutor_id,
        })
    }
}

export default BookNookApiClient
