import { call, put, takeLatest } from 'redux-saga/effects'
import {
    AUTH_CLEVER,
    AUTH_TUTOR,
    AUTH_STUDENT,
    LOGIN_TUTOR,
    LOGIN_STUDENT,
} from 'actions'
import { setError } from 'actions/error'
import ApiClient from 'util/book-nook-api-client'
import Auth from 'util/auth'
import Cookies from 'util/cookies'
import { COOKIE_CLEVER_ID } from 'util/constants'

function* cleverOAuth(action) {
    try {
        const result = yield call(
            ApiClient.authenticateCleverOAuth.bind(ApiClient),
            action.payload
        )

        if (result.type?.toUpperCase() === 'TEACHER') {
            const payload = yield Auth.postTutorAuthentication(result.data)
            yield put({ type: AUTH_TUTOR, payload })
        }
        if (result.type?.toUpperCase() === 'STUDENT') {
            const student = yield Auth.postStudentAuthentication(result.data)
            yield put({ type: AUTH_STUDENT, payload: student })
        }
    } catch (e) {
        if (e.response) {
            const error = {
                status: e.response.status,
                ...e.response.data,
            }
            yield put(setError(error, false))
        } else {
            yield put(setError(e, false))
        }
    }
}

function* tutorLogin(action) {
    try {
        const { email, password } = action.payload
        const payload = yield call(
            ApiClient.authenticateTutor.bind(ApiClient),
            email,
            password
        )

        const result = yield Auth.postTutorAuthentication(payload)
        const cleverId = Cookies.get(COOKIE_CLEVER_ID)
        if (cleverId) {
            // there was clever Id
            yield call(
                ApiClient.updateTutor.bind(ApiClient),
                payload.id,
                payload.email,
                payload.mobile_number,
                payload.first_name,
                payload.last_name,
                payload.tutor_type_id,
                cleverId,
                payload.accepted_terms_of_service
            )
            Cookies.remove(COOKIE_CLEVER_ID)
        }

        yield put({ type: AUTH_TUTOR, payload: result })
    } catch (error) {
        yield put(setError({ ...error, payload: action.payload }, true))
    }
}

function* studentLogin(action) {
    try {
        const { username } = action.payload
        const payload = yield call(
            ApiClient.authenticateStudent.bind(ApiClient),
            username
        )
        const result = yield Auth.postStudentAuthentication(payload)
        const cleverId = Cookies.get(COOKIE_CLEVER_ID)

        if (cleverId) {
            // there was clever Id
            yield call(
                ApiClient.setStudentClever.bind(ApiClient),
                payload.id,
                cleverId
            )
            Cookies.remove(COOKIE_CLEVER_ID)
        }
        yield put({ type: AUTH_STUDENT, payload: result })
    } catch (error) {
        yield put(setError({ ...error, payload: action.payload }, true))
    }
}

const auth = [
    takeLatest(AUTH_CLEVER, cleverOAuth),
    takeLatest(LOGIN_TUTOR, tutorLogin),
    takeLatest(LOGIN_STUDENT, studentLogin),
]

export default auth
