import { CLEAR_ERROR } from 'actions'
const initState = {
    data: null, // let's use keyword appError instead of error for the time being since action.error is already used by redux-promise
    isCentralized: false, // specify if an error should be handled by app centralized error handling mechanism when it is true
}

export default (state = initState, action) => {
    const { appError, isCentralized = true } = action

    if (appError) {
        return {
            data: appError,
            isCentralized,
        }
    } else if (action.type === CLEAR_ERROR) {
        return {
            data: null,
            isCentralized: false,
        }
    }

    return state
}
