import ApiClient from 'util/book-nook-api-client'
import Auth from 'util/auth'
import {
    MANAGE_SCHOOL_ADD_GUIDE_STUDENT_ACCESS,
    MANAGE_SCHOOL_ADD_GUIDE_STUDENT_ACCESS_BATCH,
    MANAGE_SCHOOL_CREATE_OR_UPDATE_GUIDE,
    MANAGE_SCHOOL_CREATE_STUDENT,
    MANAGE_SCHOOL_ENROLL_STUDENT,
    MANAGE_SCHOOL_GET_GUIDES,
    MANAGE_SCHOOL_GET_ALL_GUIDES,
    MANAGE_SCHOOL_GET_LESSONS_FOR_STANDARD,
    MANAGE_SCHOOL_GET_SELECTED_GUIDE,
    MANAGE_SCHOOL_GET_SELECTED_GUIDE_STUDENTS,
    MANAGE_SCHOOL_GET_SELECTED_LESSON,
    MANAGE_SCHOOL_GET_SELECTED_SESSION,
    MANAGE_SCHOOL_GET_SELECTED_SESSION_READING_LEVEL_CHANGES,
    MANAGE_SCHOOL_GET_SELECTED_STUDENT,
    MANAGE_SCHOOL_GET_SELECTED_STUDENT_STANDARDS,
    MANAGE_SCHOOL_GET_SESSIONS,
    MANAGE_SCHOOL_GET_STANDARDS,
    MANAGE_SCHOOL_GET_STUDENT_GROUPS,
    MANAGE_SCHOOL_GET_STUDENT_COUNT,
    MANAGE_SCHOOL_CREATE_STUDENT_GROUP,
    MANAGE_SCHOOL_GET_SELECTED_STUDENT_GROUP,
    MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT_GROUP,
    MANAGE_SCHOOL_DELETE_SELECTED_STUDENT_GROUP,
    MANAGE_SCHOOL_ADD_STUDENT_TO_SELECTED_GROUP,
    MANAGE_SCHOOL_REMOVE_STUDENT_FROM_SELECTED_GROUP,
    MANAGE_SCHOOL_REMOVE_ALL_STUDENTS_FROM_SELECTED_GROUP,
    MANAGE_SCHOOL_ADD_STUDENT_TO_NEW_GROUP,
    MANAGE_SCHOOL_RESET_NEW_GROUP,
    MANAGE_SCHOOL_REMOVE_STUDENT_FROM_NEW_GROUP,
    MANAGE_SCHOOL_GET_STUDENTS,
    MANAGE_SCHOOL_DELETE_STUDENTS,
    MANAGE_SCHOOL_UNENROLL_STUDENTS,
    MANAGE_SCHOOL_ENROLL_ALL_STUDENTS,
    MANAGE_SCHOOL_UNENROLL_ALL_STUDENTS,
    MANAGE_SCHOOL_GET_TEACHERS,
    MANAGE_SCHOOL_GET_TUTOR_TYPES,
    MANAGE_SCHOOL_IMPERSONATE_STUDENT,
    MANAGE_SCHOOL_MAKE_SCHOOL_TEACHER,
    MANAGE_SCHOOL_READING_LEVEL_REPORT,
    MANAGE_SCHOOL_REMOVE_GUIDE,
    MANAGE_SCHOOL_REMOVE_GUIDE_STUDENT_ACCESS,
    MANAGE_SCHOOL_REMOVE_GUIDE_STUDENT_ACCESS_BATCH,
    MANAGE_SCHOOL_REMOVE_SCHOOL_TEACHER,
    MANAGE_SCHOOL_RESET,
    MANAGE_SCHOOL_SET_SCHOOL,
    MANAGE_SCHOOL_SET_SELECTED_SESSION_STUDENT,
    MANAGE_SCHOOL_STRUGGLING_REPORT,
    MANAGE_SCHOOL_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT,
    MANAGE_SCHOOL_STUDENT_PROGRESS_REPORT,
    MANAGE_SCHOOL_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT,
    MANAGE_SCHOOL_STUDENT_READING_LEVEL_ROLLUP_REPORT,
    MANAGE_SCHOOL_UNENROLL_STUDENT,
    MANAGE_SCHOOL_UPDATE_SELECTED_GUIDE,
    MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT,
    MANAGE_SCHOOL_DELETE_SELECTED_STUDENT,
    MANAGE_SCHOOL_USAGE_DAILY_ROLLUP_REPORT,
    MANAGE_SCHOOL_USAGE_REPORT,
    MANAGE_SCHOOL_USAGE_WEEKLY_ROLLUP_REPORT,
    MANAGE_SCHOOL_GET_STUDENTS_NEED_STANDARD,
    MANAGE_SCHOOL_SESSION_TYPES_REPORT,
    MANAGE_SCHOOL_GET_MULTISITES,
    MANAGE_SCHOOL_MULTISITE_USAGE_REPORT,
    MANAGE_SCHOOL_MULTISITE_STRUGGLING_REPORT,
    MANAGE_SCHOOL_MULTISITE_STUDENT_PROGRESS_REPORT,
    MANAGE_SCHOOL_MULTISITE_READING_LEVEL_REPORT,
    MANAGE_SCHOOL_MULTISITE_SESSION_TYPES_REPORT,
    MANAGE_SCHOOL_MULTISITE_SCHOOL_USAGE_REPORTS,
    MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_STRUGGLING_REPORT,
    MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_REPORT,
    MANAGE_SCHOOL_GET_STANDARD_TYPES,
} from 'actions'

const DAILY_ROLLUP = 1
const WEEKLY_ROLLUP = 2

export function setSchool(school) {
    localStorage.setItem('book-nook-tutor-current-school-id', school.id)
    const payload = Promise.all([
        ApiClient.isSchoolAdmin(school.id, Auth.getLoggedInTutorId()),
        ApiClient.isSchoolTeacher(school.id, Auth.getLoggedInTutorId()),
    ]).then(([isTutorSchoolAdmin, isTutorSchoolTeacher]) => ({
        school,
        isTutorSchoolAdmin,
        isTutorSchoolTeacher,
    }))
    return {
        type: MANAGE_SCHOOL_SET_SCHOOL,
        payload: payload,
    }
}

export function makeSchoolTeacher(tutorId, schoolId) {
    const payload = ApiClient.makeSchoolTeacher(tutorId, schoolId)

    return {
        type: MANAGE_SCHOOL_MAKE_SCHOOL_TEACHER,
        payload,
    }
}

export function enrollStudent(studentId) {
    const payload = ApiClient.enrollStudent(studentId)

    return {
        type: MANAGE_SCHOOL_ENROLL_STUDENT,
        payload,
    }
}

export function unenrollStudent(studentId) {
    const payload = ApiClient.unenrollStudent(studentId)

    return {
        type: MANAGE_SCHOOL_UNENROLL_STUDENT,
        payload,
    }
}

export function removeSchoolTeacher(tutorId, schoolId) {
    const payload = ApiClient.removeSchoolTeacher(tutorId, schoolId)

    return {
        type: MANAGE_SCHOOL_REMOVE_SCHOOL_TEACHER,
        payload,
    }
}

export function getLessonsForStandard(standardId, schoolId, tutorId) {
    const payload = ApiClient.getAvailableLessons(
        [],
        null,
        null,
        standardId,
        schoolId,
        tutorId
    )

    return {
        type: MANAGE_SCHOOL_GET_LESSONS_FOR_STANDARD,
        payload,
    }
}

export function getStandards() {
    const payload = ApiClient.getAllStandards()

    return {
        type: MANAGE_SCHOOL_GET_STANDARDS,
        payload,
    }
}

export function getStudentGroups() {
    const payload = ApiClient.getStudentGroups()

    return {
        type: MANAGE_SCHOOL_GET_STUDENT_GROUPS,
        payload,
    }
}

export function resetNewGroup() {
    return {
        type: MANAGE_SCHOOL_RESET_NEW_GROUP,
    }
}

export function addStudentToNewGroup(student) {
    return {
        type: MANAGE_SCHOOL_ADD_STUDENT_TO_NEW_GROUP,
        payload: student,
    }
}

export function removeStudentFromNewGroup(student) {
    return {
        type: MANAGE_SCHOOL_REMOVE_STUDENT_FROM_NEW_GROUP,
        payload: student,
    }
}

export function createStudentGroup(studentIds, name) {
    const payload = ApiClient.createStudentGroup(studentIds, name)

    return {
        type: MANAGE_SCHOOL_CREATE_STUDENT_GROUP,
        payload,
    }
}

export function getSelectedStudentGroup(studentGroupId) {
    const payload = ApiClient.getStudentGroup(studentGroupId)

    return {
        type: MANAGE_SCHOOL_GET_SELECTED_STUDENT_GROUP,
        payload,
    }
}

export function updateStudentGroup(studentGroupId, studentIds, name) {
    const payload = ApiClient.updateStudentGroup(
        studentGroupId,
        studentIds,
        name
    )

    return {
        type: MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT_GROUP,
        payload,
    }
}

export function deleteStudentGroup(studentGroupId) {
    const payload = ApiClient.deleteStudentGroup(studentGroupId)

    return {
        type: MANAGE_SCHOOL_DELETE_SELECTED_STUDENT_GROUP,
        payload,
    }
}

export function addStudentToSelectedGroup(student) {
    return {
        type: MANAGE_SCHOOL_ADD_STUDENT_TO_SELECTED_GROUP,
        payload: student,
    }
}

export function removeStudentFromSelectedGroup(student) {
    return {
        type: MANAGE_SCHOOL_REMOVE_STUDENT_FROM_SELECTED_GROUP,
        payload: student,
    }
}

export function removeAllStudentsFromSelectedGroup(student) {
    return {
        type: MANAGE_SCHOOL_REMOVE_ALL_STUDENTS_FROM_SELECTED_GROUP,
    }
}

export function getSchoolStudents(
    schoolId,
    includeMetrics = false,
    offset = null,
    limit = null,
    orderBy = null,
    orderDirection = null,
    searchText = null,
    gradeLevelId = null,
    readingLevelId = null,
    isEnrolled = null
) {
    const payload = ApiClient.getStudentsForSchool(
        schoolId,
        includeMetrics,
        offset,
        limit,
        orderBy,
        orderDirection,
        searchText,
        gradeLevelId,
        readingLevelId,
        isEnrolled
    )

    return {
        type: MANAGE_SCHOOL_GET_STUDENTS,
        payload,
    }
}

export function deleteStudents(studentIds) {
    const payload = ApiClient.deleteStudents(studentIds)
    return {
        type: MANAGE_SCHOOL_DELETE_STUDENTS,
        meta: { studentIds },
        payload,
    }
}

export function unenrollStudents(studentIds) {
    const payload = ApiClient.unenrollStudents(studentIds)
    return {
        type: MANAGE_SCHOOL_UNENROLL_STUDENTS,
        meta: { studentIds },
        payload,
    }
}

export function enrollAllStudentsForSchool(schoolId) {
    const payload = ApiClient.enrollAllStudentsForSchool(schoolId)

    return {
        type: MANAGE_SCHOOL_ENROLL_ALL_STUDENTS,
        payload,
    }
}

export function unenrollAllStudentsForSchool(schoolId) {
    const payload = ApiClient.unenrollAllStudentsForSchool(schoolId)

    return {
        type: MANAGE_SCHOOL_UNENROLL_ALL_STUDENTS,
        payload,
    }
}

export function getTutorStudents() {
    const payload = ApiClient.getAllStudents()

    return {
        type: MANAGE_SCHOOL_GET_STUDENTS,
        payload,
    }
}

export function getSchoolTeachers(schoolId) {
    const payload = ApiClient.getTeachersForSchool(schoolId)

    return {
        type: MANAGE_SCHOOL_GET_TEACHERS,
        payload,
    }
}

export function getSchoolSessions(schoolId, offset, count) {
    const payload = ApiClient.getSessionsForSchool(schoolId, offset, count)

    return {
        type: MANAGE_SCHOOL_GET_SESSIONS,
        payload,
    }
}

export function getTutorSessions(tutorId, offset, count, schoolId) {
    const payload = ApiClient.getSessionsForTutor(
        tutorId,
        offset,
        count,
        schoolId
    )

    return {
        type: MANAGE_SCHOOL_GET_SESSIONS,
        payload,
    }
}

export function getStudentSessions(studentId, offset, count) {
    const payload = ApiClient.getSessionsForStudent(studentId, offset, count)

    return {
        type: MANAGE_SCHOOL_GET_SESSIONS,
        payload,
    }
}

export function getSelectedLesson(lessonId) {
    const payload = ApiClient.getLesson(lessonId)

    return {
        type: MANAGE_SCHOOL_GET_SELECTED_LESSON,
        payload,
    }
}

export function getSelectedSession(sessionId) {
    const payload = ApiClient.getSession(sessionId).then(async session => {
        const sessionStudents = await ApiClient.getSessionStudents(
            sessionId,
            true
        )
        const students = sessionStudents.map(ss => {
            const { student, ...others } = ss
            const {
                username,
                first_name,
                last_name,
                reading_level_id,
            } = student
            return {
                ...others,
                first_name,
                username,
                last_name,
                reading_level_id,
            }
        })
        session.students = students
        return session
    })

    return {
        type: MANAGE_SCHOOL_GET_SELECTED_SESSION,
        payload,
    }
}

export function getSelectedSessionReadingLevelChanges(sessionId, studentIds) {
    const payload = ApiClient.getSessionReadingLevelChanges(
        sessionId,
        studentIds
    )

    return {
        type: MANAGE_SCHOOL_GET_SELECTED_SESSION_READING_LEVEL_CHANGES,
        payload,
    }
}

export function setSelectedSessionStudent(student) {
    return {
        type: MANAGE_SCHOOL_SET_SELECTED_SESSION_STUDENT,
        payload: student,
    }
}

export function getSelectedStudent(studentId) {
    const payload = ApiClient.getStudent(studentId)

    return {
        type: MANAGE_SCHOOL_GET_SELECTED_STUDENT,
        payload,
    }
}

export function getSelectedStudentStandards(readingLevelId) {
    const payload = ApiClient.getAllStandards(null, readingLevelId)

    return {
        type: MANAGE_SCHOOL_GET_SELECTED_STUDENT_STANDARDS,
        payload,
    }
}

/**
 *
 * @param {StudentCreate} student
 */
export function createStudent(student) {
    const payload = ApiClient.createStudent(student)

    return {
        type: MANAGE_SCHOOL_CREATE_STUDENT,
        payload,
    }
}

/**
 *
 * @param {StudentUpdate} student
 */
export function updateStudent(student) {
    const payload = ApiClient.updateStudent(student)

    return {
        type: MANAGE_SCHOOL_UPDATE_SELECTED_STUDENT,
        payload,
    }
}

export function deleteStudent(studentId) {
    const payload = ApiClient.deleteStudent(studentId)

    return {
        type: MANAGE_SCHOOL_DELETE_SELECTED_STUDENT,
        payload,
    }
}

export function getGuides(
    schoolId,
    offset,
    limit,
    orderBy,
    orderDirection,
    searchText
) {
    const payload = ApiClient.getSchoolGuides(
        schoolId,
        true,
        offset,
        limit,
        orderBy,
        orderDirection,
        searchText
    )

    return {
        type: MANAGE_SCHOOL_GET_GUIDES,
        payload,
    }
}

export function getStudentCount(query) {
    const payload = ApiClient.getStudentCountForSchool(query)

    return {
        type: MANAGE_SCHOOL_GET_STUDENT_COUNT,
        payload,
    }
}

export function getAllGuides(schoolId) {
    const payload = ApiClient.getSchoolGuides(schoolId, true, false)

    return {
        type: MANAGE_SCHOOL_GET_ALL_GUIDES,
        payload,
    }
}

export function getSelectedGuide(schoolId, guideId) {
    const payload = ApiClient.getTutorForSchool(schoolId, guideId)

    return {
        type: MANAGE_SCHOOL_GET_SELECTED_GUIDE,
        payload,
    }
}

export function getSelectedGuideStudents(id, schoolId) {
    const payload = ApiClient.getTutorStudentsAtSchool(id, schoolId)

    return {
        type: MANAGE_SCHOOL_GET_SELECTED_GUIDE_STUDENTS,
        payload,
    }
}

/**
 *
 * @param {TutorImport} tutor
 */
export function createOrUpdateGuide(tutor) {
    const payload = ApiClient.importTutor(tutor)

    return {
        type: MANAGE_SCHOOL_CREATE_OR_UPDATE_GUIDE,
        payload,
    }
}

/**
 *
 * @param {TutorUpdate} tutor
 */
export function updateTutorForSchool(tutor) {
    const payload = ApiClient.updateTutorForSchool(tutor)

    return {
        type: MANAGE_SCHOOL_UPDATE_SELECTED_GUIDE,
        payload,
    }
}

export function addStudentAccess(guideId, studentId) {
    const payload = ApiClient.addStudentAccess(guideId, studentId)

    return {
        type: MANAGE_SCHOOL_ADD_GUIDE_STUDENT_ACCESS,
        payload,
    }
}

export function addStudentAccessBatch(id, studentIds) {
    const payload = ApiClient.addStudentAccessBatch(id, studentIds)

    return {
        type: MANAGE_SCHOOL_ADD_GUIDE_STUDENT_ACCESS_BATCH,
        payload,
    }
}

export function removeStudentAccess(guideId, studentId) {
    const payload = ApiClient.removeStudentAccess(guideId, studentId)

    return {
        type: MANAGE_SCHOOL_REMOVE_GUIDE_STUDENT_ACCESS,
        payload,
    }
}

export function removeStudentAccessBatch(id, studentIds) {
    const payload = ApiClient.removeStudentAccessBatch(id, studentIds)

    return {
        type: MANAGE_SCHOOL_REMOVE_GUIDE_STUDENT_ACCESS_BATCH,
        payload,
    }
}

export function removeGuideFromSchool(schoolId, guideId) {
    const payload = ApiClient.removeTutorFromSchool(schoolId, guideId)

    return {
        type: MANAGE_SCHOOL_REMOVE_GUIDE,
        payload,
    }
}

export function impersonateStudent(studentId) {
    const payload = ApiClient.impersonateStudent(studentId).then(
        Auth.postStudentAuthentication
    )

    return {
        type: MANAGE_SCHOOL_IMPERSONATE_STUDENT,
        payload,
    }
}

export function getUsageReportForSchool(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId,
    sessionTypeCode,
    includeIncompleteSessions
) {
    const payload = ApiClient.getUsageReportForSchool(
        startDate,
        endDate,
        schoolId,
        tutorId,
        gradeLevelId,
        sessionTypeCode,
        includeIncompleteSessions
    )

    return {
        type: MANAGE_SCHOOL_USAGE_REPORT,
        payload,
    }
}

export function getUsageReportForGuide(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId,
    sessionTypeCode,
    includeIncompleteSessions
) {
    const payload = ApiClient.getUsageReportForGuide(
        startDate,
        endDate,
        schoolId,
        tutorId,
        gradeLevelId,
        sessionTypeCode,
        includeIncompleteSessions
    )

    return {
        type: MANAGE_SCHOOL_USAGE_REPORT,
        payload,
    }
}

export function getUsageReportForStudent(
    startDate,
    endDate,
    studentId,
    sessionTypeCode,
    includeIncompleteSessions
) {
    const payload = ApiClient.getUsageReportForStudent(
        startDate,
        endDate,
        studentId,
        sessionTypeCode,
        includeIncompleteSessions
    )

    return {
        type: MANAGE_SCHOOL_USAGE_REPORT,
        payload,
    }
}

export function getUsageDailyRollupReportForSchool(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId,
    sessionTypeCode,
    includeIncompleteSessions
) {
    const payload = ApiClient.getUsageRollupReportForSchool(
        startDate,
        endDate,
        DAILY_ROLLUP,
        schoolId,
        tutorId,
        gradeLevelId,
        sessionTypeCode,
        includeIncompleteSessions
    )

    return {
        type: MANAGE_SCHOOL_USAGE_DAILY_ROLLUP_REPORT,
        payload,
    }
}

export function getUsageDailyRollupReportForGuide(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId,
    sessionTypeCode,
    includeIncompleteSessions
) {
    const payload = ApiClient.getUsageRollupReportForGuide(
        startDate,
        endDate,
        DAILY_ROLLUP,
        schoolId,
        tutorId,
        gradeLevelId,
        sessionTypeCode,
        includeIncompleteSessions
    )

    return {
        type: MANAGE_SCHOOL_USAGE_DAILY_ROLLUP_REPORT,
        payload,
    }
}

export function getUsageWeeklyRollupReportForSchool(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId,
    sessionTypeCode,
    includeIncompleteSessions
) {
    const payload = ApiClient.getUsageRollupReportForSchool(
        startDate,
        endDate,
        WEEKLY_ROLLUP,
        schoolId,
        tutorId,
        gradeLevelId,
        sessionTypeCode,
        includeIncompleteSessions
    )

    return {
        type: MANAGE_SCHOOL_USAGE_WEEKLY_ROLLUP_REPORT,
        payload,
    }
}

export function getUsageWeeklyRollupReportForGuide(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId,
    sessionTypeCode,
    includeIncompleteSessions
) {
    const payload = ApiClient.getUsageRollupReportForGuide(
        startDate,
        endDate,
        WEEKLY_ROLLUP,
        schoolId,
        tutorId,
        gradeLevelId,
        sessionTypeCode,
        includeIncompleteSessions
    )

    return {
        type: MANAGE_SCHOOL_USAGE_WEEKLY_ROLLUP_REPORT,
        payload,
    }
}

export function getUsageWeeklyRollupReportForStudent(
    startDate,
    endDate,
    studentId,
    sessionTypeCode,
    includeIncompleteSessions
) {
    const payload = ApiClient.getUsageRollupReportForStudent(
        startDate,
        endDate,
        WEEKLY_ROLLUP,
        studentId,
        sessionTypeCode,
        includeIncompleteSessions
    )

    return {
        type: MANAGE_SCHOOL_USAGE_WEEKLY_ROLLUP_REPORT,
        payload,
    }
}

export function getStudentProgressReportForSchool(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId
) {
    const payload = ApiClient.getStudentProgressReportForSchool(
        startDate,
        endDate,
        schoolId,
        tutorId,
        gradeLevelId
    )

    return {
        type: MANAGE_SCHOOL_STUDENT_PROGRESS_REPORT,
        payload,
    }
}

export function getStudentProgressReportForGuide(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId
) {
    const payload = ApiClient.getStudentProgressReportForGuide(
        startDate,
        endDate,
        schoolId,
        tutorId,
        gradeLevelId
    )

    return {
        type: MANAGE_SCHOOL_STUDENT_PROGRESS_REPORT,
        payload,
    }
}

export function getStudentProgressReportForStudent(
    startDate,
    endDate,
    studentId
) {
    const payload = ApiClient.getStudentProgressReportForStudent(
        startDate,
        endDate,
        studentId
    )

    return {
        type: MANAGE_SCHOOL_STUDENT_PROGRESS_REPORT,
        payload,
    }
}

export function getStudentProgressDailyRollupReportForSchool(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId
) {
    const payload = ApiClient.getStudentProgressRollupReportForSchool(
        startDate,
        endDate,
        DAILY_ROLLUP,
        schoolId,
        tutorId,
        gradeLevelId
    )

    return {
        type: MANAGE_SCHOOL_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT,
        payload,
    }
}

export function getStudentProgressDailyRollupReportForGuide(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId
) {
    const payload = ApiClient.getStudentProgressRollupReportForGuide(
        startDate,
        endDate,
        DAILY_ROLLUP,
        schoolId,
        tutorId,
        gradeLevelId
    )

    return {
        type: MANAGE_SCHOOL_STUDENT_PROGRESS_DAILY_ROLLUP_REPORT,
        payload,
    }
}

export function getStudentProgressWeeklyRollupReportForSchool(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId
) {
    const payload = ApiClient.getStudentProgressRollupReportForSchool(
        startDate,
        endDate,
        WEEKLY_ROLLUP,
        schoolId,
        tutorId,
        gradeLevelId
    )

    return {
        type: MANAGE_SCHOOL_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT,
        payload,
    }
}

export function getStudentProgressWeeklyRollupReportForGuide(
    startDate,
    endDate,
    schoolId,
    tutorId,
    gradeLevelId
) {
    const payload = ApiClient.getStudentProgressRollupReportForGuide(
        startDate,
        endDate,
        WEEKLY_ROLLUP,
        schoolId,
        tutorId,
        gradeLevelId
    )

    return {
        type: MANAGE_SCHOOL_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT,
        payload,
    }
}

export function getStudentProgressWeeklyRollupReportForStudent(
    startDate,
    endDate,
    studentId
) {
    const payload = ApiClient.getStudentProgressRollupReportForStudent(
        startDate,
        endDate,
        WEEKLY_ROLLUP,
        studentId
    )

    return {
        type: MANAGE_SCHOOL_STUDENT_PROGRESS_WEEKLY_ROLLUP_REPORT,
        payload,
    }
}

export function getReadingLevelReportForSchool(
    schoolId,
    tutorId,
    gradeLevelId
) {
    const payload = ApiClient.getReadingLevelReportForSchool(
        schoolId,
        tutorId,
        gradeLevelId
    )

    return {
        type: MANAGE_SCHOOL_READING_LEVEL_REPORT,
        payload,
    }
}

export function getReadingLevelReportForGuide(schoolId, tutorId, gradeLevelId) {
    const payload = ApiClient.getReadingLevelReportForGuide(
        schoolId,
        tutorId,
        gradeLevelId
    )

    return {
        type: MANAGE_SCHOOL_READING_LEVEL_REPORT,
        payload,
    }
}

export function getStudentReadingLevelWeeklyRollupReportForStudent(
    startDate,
    endDate,
    studentId
) {
    const payload = ApiClient.getStudentReadingLevelRollupReportForStudent(
        startDate,
        endDate,
        WEEKLY_ROLLUP,
        studentId
    )

    return {
        type: MANAGE_SCHOOL_STUDENT_READING_LEVEL_ROLLUP_REPORT,
        payload,
    }
}

export function getStrugglingReportForSchool(schoolId) {
    const payload = ApiClient.getStrugglingReportForSchool(schoolId)

    return {
        type: MANAGE_SCHOOL_STRUGGLING_REPORT,
        payload,
    }
}

export function getStrugglingReportForGuide(tutorId) {
    const payload = ApiClient.getStrugglingReportForGuide(tutorId)

    return {
        type: MANAGE_SCHOOL_STRUGGLING_REPORT,
        payload,
    }
}

export function getSessionTypesReportForSchool(startDate, endDate, schoolId) {
    const payload = ApiClient.getSessionTypesReportForSchool(
        startDate,
        endDate,
        schoolId
    )

    return {
        type: MANAGE_SCHOOL_SESSION_TYPES_REPORT,
        payload,
    }
}

export function getSessionTypesReportForGuide(
    startDate,
    endDate,
    schoolId,
    tutorId
) {
    const payload = ApiClient.getSessionTypesReportForGuide(
        startDate,
        endDate,
        schoolId,
        tutorId
    )

    return {
        type: MANAGE_SCHOOL_SESSION_TYPES_REPORT,
        payload,
    }
}

export function getTutorTypes() {
    const payload = ApiClient.getTutorTypes()

    return {
        type: MANAGE_SCHOOL_GET_TUTOR_TYPES,
        payload,
    }
}

export function reset() {
    return { type: MANAGE_SCHOOL_RESET }
}

export function getStudentsNeedStandard(schoolId, standardId) {
    const payload = ApiClient.getStudentsNeedStandard(schoolId, standardId)
    return {
        type: MANAGE_SCHOOL_GET_STUDENTS_NEED_STANDARD,
        payload,
    }
}

export function getMultisites(schoolId) {
    const payload = ApiClient.getMultisites(schoolId)

    return {
        type: MANAGE_SCHOOL_GET_MULTISITES,
        payload,
    }
}

export function getUsageReportForMultisite(startDate, endDate, multisiteId) {
    const payload = ApiClient.getUsageReportForMultisite(
        startDate,
        endDate,
        multisiteId
    )

    return {
        type: MANAGE_SCHOOL_MULTISITE_USAGE_REPORT,
        payload,
    }
}

export function getStrugglingReportForMultisite(multisiteId) {
    const payload = ApiClient.getStrugglingReportForMultisite(multisiteId)

    return {
        type: MANAGE_SCHOOL_MULTISITE_STRUGGLING_REPORT,
        payload,
    }
}

export function getStudentProgressReportForMultisite(
    startDate,
    endDate,
    multisiteId
) {
    const payload = ApiClient.getStudentProgressReportForMultisite(
        startDate,
        endDate,
        multisiteId
    )

    return {
        type: MANAGE_SCHOOL_MULTISITE_STUDENT_PROGRESS_REPORT,
        payload,
    }
}

export function getReadingLevelReportForMultisite(multisiteId) {
    const payload = ApiClient.getReadingLevelReportForMultisite(multisiteId)

    return {
        type: MANAGE_SCHOOL_MULTISITE_READING_LEVEL_REPORT,
        payload,
    }
}

export function getSessionTypesReportForMultisite(
    startDate,
    endDate,
    multisiteId
) {
    const payload = ApiClient.getSessionTypesReportForMultisite(
        startDate,
        endDate,
        multisiteId
    )

    return {
        type: MANAGE_SCHOOL_MULTISITE_SESSION_TYPES_REPORT,
        payload,
    }
}

export function getSchoolUsageReportsForMultisite(multisite) {
    const payload = Promise.all(
        multisite.schools.map(s =>
            ApiClient.getUsageReportForSchool(
                new Date(s.school_year_start),
                new Date(s.school_year_end),
                s.id
            )
        )
    )

    return {
        type: MANAGE_SCHOOL_MULTISITE_SCHOOL_USAGE_REPORTS,
        payload,
    }
}

export function getPhonicsActivityStrugglingReportForSchool(schoolId) {
    const payload = ApiClient.getPhonicsActivityStrugglingReportForSchool(
        schoolId
    )

    return {
        type: MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_STRUGGLING_REPORT,
        payload,
    }
}

export function getPhonicsActivityReportForSchool(schoolId) {
    const payload = ApiClient.getPhonicsActivityReportForSchool(schoolId)

    return {
        type: MANAGE_SCHOOL_GET_PHONICS_ACTIVITY_REPORT,
        payload,
    }
}

export function getAllStandardTypes() {
    const payload = ApiClient.getAllStandardTypes()

    return {
        type: MANAGE_SCHOOL_GET_STANDARD_TYPES,
        payload,
    }
}
