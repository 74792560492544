import {
    COMPREHENSION_LESSON_TYPE,
    FLUENCY_LESSON_TYPE,
    K_ONE_LESSON_TYPE,
} from 'util/lesson-types'

export const CLASSROOM_SESSION_TYPE = 'CLASSROOM'
export const GUIDED_SESSION_TYPE = 'GUIDED'
export const LEVELING_SESSION_TYPE = 'LEVELING'
export const WHOLE_CLASS_SESSION_TYPE = 'WHOLE_CLASS'
export const INDEPENDENT_SESSION_TYPE = 'INDEPENDENT'
export const FULL_SESSION_TYPE = 'FULL' // TODO remove full session type reference from leveling

// combine any whole class session types into guided session types
export const mergeSessionTypes = sessionTypesReport => {
    const mergedReport = [...sessionTypesReport]
    const lessonTypes = [
        COMPREHENSION_LESSON_TYPE,
        FLUENCY_LESSON_TYPE,
        K_ONE_LESSON_TYPE,
    ]

    lessonTypes.forEach(lt => {
        const guided = mergedReport.find(
            st =>
                st.session_type_code === GUIDED_SESSION_TYPE &&
                st.lesson_type_code === lt
        )
        const wholeClass = mergedReport.find(
            st =>
                st.session_type_code === WHOLE_CLASS_SESSION_TYPE &&
                st.lesson_type_code === lt
        )

        if (guided && wholeClass) {
            guided.num_sessions += wholeClass.num_sessions
        } else if (wholeClass) {
            wholeClass.session_type_code = GUIDED_SESSION_TYPE
        }
    })

    return mergedReport.filter(
        st => st.session_type_code !== WHOLE_CLASS_SESSION_TYPE
    )
}
