import {
    ADMIN_STUDENTS_GET_ALL,
    ADMIN_STUDENTS_GET_SCHOOLS,
    ADMIN_STUDENTS_EDIT,
    ADMIN_STUDENTS_GET_COUNT,
    ADMIN_STUDENTS_SELECTED,
} from 'actions'

const DEFAULT_STATE = {
    students: [],
    studentsCount: 0,
    readingLevels: [],
    gradeLevels: [],
    genderTypes: [],
    races: [],
    selectedStudent: null,
}

export default (state = DEFAULT_STATE, action) => {
    if (action.error) return state

    switch (action.type) {
        case ADMIN_STUDENTS_GET_ALL:
            return {
                ...state,
                students: action.payload,
            }
        case ADMIN_STUDENTS_GET_SCHOOLS:
            return {
                ...state,
                schools: action.payload,
            }
        case ADMIN_STUDENTS_EDIT:
            return {
                ...state,
                selectedStudent: action.payload,
            }
        case ADMIN_STUDENTS_GET_COUNT:
            return {
                ...state,
                studentsCount: action.payload.count,
            }
        case ADMIN_STUDENTS_SELECTED:
            return {
                ...state,
                selectedStudent: action.payload,
            }
        default:
            return state
    }
}
