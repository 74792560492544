import ApiClient from 'util/book-nook-api-client'
import {
    GET_LIST_PROGRAMS,
    GET_PROGRAM,
    CREATE_PROGRAM,
    UPDATE_PROGRAM,
    REMOVE_PROGRAM,
    GET_PROGRAM_TUTORS,
    GET_PROGRAM_TUTORS_COUNT,
    UPDATE_TUTOR_PROGRAM,
    GET_PROGRAM_STUDENTS,
    GET_PROGRAM_STUDENTS_COUNT,
    UPDATE_STUDENT_PROGRAM,
    UPDATE_TIMEBLOCK_PROGRAM,
    GET_PROGRAM_TIMEBLOCKS,
    GET_METRICS_PROGRAM,
    GET_TUTORING_GROUPS,
    GET_TUTORING_GROUP,
    IMPORT_STUDENT_TO_PROGRAM,
    IMPORT_TUTOR_TO_PROGRAM,
    GET_TUTORING_GROUP_STUDENTS,
    DELETE_TUTORING_GROUP,
    GET_TUTORING_GROUPS_COUNT,
} from 'actions'

export function getAllPrograms() {
    const payload = ApiClient.getAllPrograms()
    return { type: GET_LIST_PROGRAMS, payload }
}

export function getProgram(id) {
    const payload = ApiClient.getProgram(id)
    return { type: GET_PROGRAM, payload }
}

export function createProgram(name, description) {
    const payload = ApiClient.createProgram(name, description)
    return { type: CREATE_PROGRAM, payload }
}

export function removeProgram(id) {
    const payload = ApiClient.removeProgram(id)
    return { type: REMOVE_PROGRAM, payload }
}

export function updateProgram(id, name, description) {
    const payload = ApiClient.updateProgram(id, name, description)
    return { type: UPDATE_PROGRAM, payload }
}

export function getProgramTutors(
    id,
    onlyWithProgram,
    limit,
    offset,
    searchText
) {
    const payload = ApiClient.getProgramTutors(id, {
        onlyWithProgram,
        limit,
        offset,
        searchText,
    })
    return { type: GET_PROGRAM_TUTORS, payload }
}

export function updateTutorProgram(id, tutorId, isAssigned) {
    const payload = ApiClient.updateTutorProgram(id, tutorId, isAssigned)
    return { type: UPDATE_TUTOR_PROGRAM, payload }
}

export function getProgramTutorsCount(id, onlyWithProgram, searchText) {
    const payload = ApiClient.getProgramTutorsCount(id, {
        onlyWithProgram,
        searchText,
    })
    return { type: GET_PROGRAM_TUTORS_COUNT, payload }
}

export function getProgramStudents(
    id,
    onlyWithProgram,
    limit,
    offset,
    searchText
) {
    const payload = ApiClient.getProgramStudents(id, {
        onlyWithProgram,
        limit,
        offset,
        searchText,
    })
    return { type: GET_PROGRAM_STUDENTS, payload }
}

export function updateStudentProgram(id, studentId, isAssigned) {
    const payload = ApiClient.updateStudentProgram(id, studentId, isAssigned)
    return { type: UPDATE_STUDENT_PROGRAM, payload }
}

export function getProgramStudentsCount(id, onlyWithProgram, searchText) {
    const payload = ApiClient.getProgramStudentsCount(id, {
        onlyWithProgram,
        searchText,
    })
    return { type: GET_PROGRAM_STUDENTS_COUNT, payload }
}

export function updateTimeblockProgram(id, timeblockId) {
    const payload = ApiClient.updateTimeblockProgram(id, timeblockId)
    return { type: UPDATE_TIMEBLOCK_PROGRAM, payload }
}

export function getProgramTimeblocks(id) {
    const payload = ApiClient.getProgramTimeblocks(id)
    return { type: GET_PROGRAM_TIMEBLOCKS, payload }
}

export function getMetricsProgram(id) {
    const payload = ApiClient.getMetricsProgram(id)
    return { type: GET_METRICS_PROGRAM, payload }
}

export function getTutoringGroups(limit, offset) {
    const payload = ApiClient.getTutoringGroups(limit, offset)
    return { type: GET_TUTORING_GROUPS, payload }
}

export function getTutoringGroupsCount() {
    const payload = ApiClient.getTutoringGroupsCount()
    return { type: GET_TUTORING_GROUPS_COUNT, payload }
}

export function getTutoringGroup(id) {
    const payload = ApiClient.getTutoringGroup(id)
    return { type: GET_TUTORING_GROUP, payload }
}

export function getTutoringGroupStudents(id) {
    const payload = ApiClient.getTutoringGroupStudents(id)
    return { type: GET_TUTORING_GROUP_STUDENTS, payload }
}

export function deleteTutoringGroup(id) {
    const payload = ApiClient.deleteTutoringGroup(id)
    return { type: DELETE_TUTORING_GROUP, payload }
}

export function importStudentToProgram(studentId, programId) {
    const payload = ApiClient.importStudentToProgram(studentId, programId)
    return {
        type: IMPORT_STUDENT_TO_PROGRAM,
        payload,
    }
}

export function importTutorToProgram(tutorId, programId) {
    const payload = ApiClient.importTutorToProgram(tutorId, programId)

    return {
        type: IMPORT_TUTOR_TO_PROGRAM,
        payload,
    }
}
