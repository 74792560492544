import { SET_ERROR, CLEAR_ERROR } from 'actions'
export function setError(error, isCentralized) {
    return {
        type: SET_ERROR,
        appError: error,
        isCentralized,
    }
}

export function hideError() {
    return {
        type: CLEAR_ERROR,
    }
}
